import {
  ArrowUpward,
  BarChart,
  CalendarMonth,
  CastForEducationOutlined,
  Details,
  FemaleOutlined,
  GirlOutlined,
  Group,
  GroupOutlined,
  Language,
  LocationCityOutlined,
  Lock,
  LockOutlined,
  Mail,
  MailOutline,
  Person,
  Person2Outlined,
  Person3Outlined,
  PersonOutline,
  Phone,
  PhoneOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PeopleIcon from "@mui/icons-material/People";
import HeightIcon from "@mui/icons-material/Height";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import img1 from "../../Assets/userprofile/img1.png";
import img2 from "../../Assets/userprofile/img2.png";
import mother from "../../Assets/userprofile/mother.jpg";
import father from "../../Assets/userprofile/father.png";
import match from "../../Assets/userprofile/match.png";
import connect from "../../Assets/userprofile/connection.png";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./UserProfile.css";
import EditIcon from "@mui/icons-material/Edit";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import ChatIcon from "@mui/icons-material/Chat";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import vagan from "../../Assets/userprofile/vagan.png";
import drink from "../../Assets/userprofile/drink.png";
import smoke from "../../Assets/userprofile/smoke.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import Button from 'react-bootstrap/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';


// import PhoneIcon from "@mui/icons-material/Phone";
import crown from "./crown.png";
import SchoolIcon from "@mui/icons-material/School";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../BASE_URL";

import { OverlayTrigger, Popover } from "react-bootstrap";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel variant="dark">
          {Array.isArray(props?.userPhotos) &&
            props?.userPhotos?.map((ele) => (
              <Carousel.Item key={ele}>
                <img className="d-block w-100" src={ele} alt="First slide" />
              </Carousel.Item>
            ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

const UserDetailById = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is not available
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      // If token is not available, navigate to the home page
      navigate("/");
    }
  }, []);


  const location = useLocation();

  const stateData = location.state;

  const [details, setDetails] = useState("");

  const { id } = useParams();
  const [family, setFamily] = useState("");
  const [familyValidation, setFamilyValidation] = useState([]);
  const [preference, setPreference] = useState("");
  const [religion, setReligion] = useState([]);
  const [language, setLanguage] = useState([]);
  const [contactDetail, setcontactDetail] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [otherDoc, setOtherDoc] = useState([]);
  const [otherSocial, setOtherSocial] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [otherFamily, setOtherFamily] = useState("");
  const [otherBirth, setOtherBirth] = useState("");
  const [hobbies, setHobbies] = useState([]);
  const [aassets, setAssets] = useState([])
  const [currentLocation, setCurrentLocation] = useState([]);
  const [buttonStatus, setButtonStatus] = useState("")
  console.log(buttonStatus)

  useEffect(() => {

    if (location.status === "") {

      if (details?.request_status === null) {
        setButtonStatus("matched")
      } else if (Details?.request_status === "pending" && details?.action === "receiver") {
        setButtonStatus("received")
      } else if (Details?.request_status == "pending" && details?.action == "sender") {
        setButtonStatus("sent")
      } else if (Details?.request_status == "confirm" && details?.action == "sender") {
        setButtonStatus("accepted")
      } else if (Details?.request_status == "decline" && details?.action == "receiver") {
        setButtonStatus("decline")
      } else if (Details?.request_status == "cancel" && details?.action == "sender") {
        setButtonStatus("cancel")
      } else if (Details?.request_status == "confirm" && details?.action == "sender") {
        setButtonStatus("accepted")
      } else {
        setButtonStatus("not matched")
      }

    } else {
      setButtonStatus(location?.state?.status)
    }

  }, []);

  const userDetails = async () => {
    try {
      const token = localStorage.getItem("saptvidhiUserToken");
      const res = await fetch(
        `${BASE_URL}/api/profile/userdetails?user_id=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();

      setDetails(data?.data?.UserDetails);

      const userOtherInfos = data?.data?.UserDetails?.user_other_infos;
      if (userOtherInfos != null) {
        setOtherDoc(userOtherInfos.social_media);
        setOtherSocial(userOtherInfos.doc_verification);
        setOtherInfo(userOtherInfos);
        setAssets(userOtherInfos.assets);
        setCurrentLocation(userOtherInfos);
        setHobbies(userOtherInfos.hobbies_name);
        setOtherBirth(userOtherInfos)
      }

      setcontactDetail(data?.data?.contact_info);
      setFamilyValidation(data?.data?.UserDetails?.family)
      if (data?.data?.UserDetails?.family?.length > 0) {
        setFamily(data?.data?.UserDetails?.family?.[0]);
      }
      setPreference(data?.data?.UserDetails?.preference?.[0]);
      setReligion(data?.data?.UserDetails?.preference?.[0]?.religion);
      setLanguage(data?.data?.UserDetails?.preference?.[0]?.language);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const member_type = localStorage.getItem("memberType");
  const handlePendingConnect = async () => {
    handleClose111();
    try {
      const response = await fetch(`${BASE_URL}/api/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
        body: JSON.stringify({
          receiver_id: id,
          request_status: "pending",
          request_type: "request",
        }),
      });

      if (response.status == 200) {
        toast.success('Request Send Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };


  const [showModal1, setShowModal1] = useState(false);

  const openModal1 = () => {
    setShowModal1(true);
  };

  const closeModal1 = () => {
    setShowModal1(false);
  };


  const handleUnblock = async (id) => {
    try {
      await axios
        .delete(`${BASE_URL}/api/blockuser?_id=${id}`, {
          headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
        })
        .then((res) => {
          if (res.data.code === 200) {
            userDetails();
            closeModal1();
            toast.success(`${details?.user_name} Unbloked Successfully`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            });

          }
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  };
  const handleSentCancel = async (_id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            // receiver_id: data._id,
            request_status: "cancel",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        userDetails();
        toast.error('Request decline Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };
  const handleDeclineCancel = async (_id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?request_id=${_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );
      if (response.status == 200) {
        userDetails();
        toast.error('Request Deleted Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };
  const handleDeclineDecline = async (_id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            // receiver_id: data._id,
            request_status: "confirm",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        userDetails();
        toast.error('Request Resend Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };
  const handleInviteAccept = async (_id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            // receiver_id: data._id,
            request_status: "confirm",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        userDetails();
        toast.error('Request Accept Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };
  const handleInviteDecline = async (_id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            // receiver_id: data._id,
            request_status: "decline",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        userDetails();
        toast.error('Request decline Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };
  const handleAcceptDecline = async (_id) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/request?_id=${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({
            // receiver_id: data._id,
            request_status: "decline",
            request_type: "request",
          }),
        }
      );
      if (response.status == 200) {
        userDetails();
        toast.error('Request decline Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      alert(err);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    handleClose111();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleBlock = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/blockuser`,
        { block_id: id },
        {
          headers: {
            Authorization: localStorage.getItem("saptvidhiUserToken"),
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.code === 200) {
        toast.success('block successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        closeModal();
      }
    } catch (err) {
      alert(err);
    }
  };
  useEffect(() => {
    userDetails();
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const [seenContactDetails, setSeenContactDetails] = useState();
  const [showContactDetails, setShowContactDetails] = useState(false);
  const handleClickYes = async () => {
    try {
      const token = localStorage.getItem("saptvidhiUserToken");
      const response = await axios.post(
        `${BASE_URL}/api/count_of_contact_details`,
        { profile_id: id },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.code === 200) {
        setSeenContactDetails(response.data.data);
      } else {
        toast.error('your data limit is over', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (err) { }
  };

  const [Yay, setYay] = useState("");

  const Call = async () => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(`${BASE_URL}/api/profile/checkProfile`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setYay(data?.data?.user[0]);
  };

  useEffect(() => {
    Call();
  }, []);

  const [showSecondModal, setShowSecondModal] = useState(false);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShowSecondModal(false);
  };

  const handleShow1 = async () => {
    setShow(false);

    setShowSecondModal(true);

    try {
      const token = localStorage.getItem("saptvidhiUserToken");
      const response = await axios.post(
        `${BASE_URL}/api/count_of_contact_details`,
        { profile_id: details?._id },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.code === 200) {
        setSeenContactDetails(response.data.data);
      } else {
        toast.error('Your date limit data over', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (err) {
    }
  };

  const handlePaidMember = () => {
    if (member_type === "paid") {
      setShow(true);
    } else (
      navigate("/selectplan")
    )
  }

  const handleShortlistDelete = async () => {
    try {
      await axios
        .delete(
          `${BASE_URL}/api/shortlist?shortlist_id=${id}`,
          {
            headers: { Authorization: localStorage.getItem("saptvidhiUserToken") },
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            toast.success('Removed From ShortList!', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            });
          }
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose111 = () => {
    setAnchorEl(null);
  };

  const [profilePhoto, setProfilePhoto] = useState("");

  useEffect(() => {
    const storedProfilePhoto = localStorage.getItem("myprofilePhoto");

    try {
      const parsedProfilePhoto = JSON.parse(storedProfilePhoto);

      setProfilePhoto(parsedProfilePhoto);
    } catch (e) {
      setProfilePhoto(storedProfilePhoto);  // If it was stored as a string directly
    }
  }, []);


  return (
    <div className="userprofile">
      <div className="userprofile__left">
        <div className="userprofile_profile">
          <div
            className="userprofile_profile_top"
            style={{ position: "relative" }}
          >
            {details?.profile_photo ? (
              <img
                src={details?.profile_photo}
                alt=""
                style={{ borderRadius: "100%" }}
                onClick={() => setModalShow(true)}
              />
            ) : (
              details?.user_photo
            )}
            {details?.member_type === "paid" && (
              <img
                src={crown}
                className="userdetail-by-id-crown"
                alt=""
              />
            )}
          </div>
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            userPhotos={details?.user_photo}
          />

          <div className="userprofile_profile_bottom">
            <div className="userprofile_profile_bottom_detail_wrapper">
              <PhoneOutlined />
              {contactDetail?.contact_no}
            </div>
            <div className="userprofile_profile_bottom_detail_wrapper">
              <MailOutline />
              {contactDetail?.Email_id}
            </div>
            <div className="userprofile_profile_bottom_detail_wrapper">
              <LockOutlined />
              {details?.member_type} Member
            </div>
          </div>
        </div>
        <div className="userprofile_matches">
          <div className="userprofile_matches_profile_images">
            <img
              src={connect}
              alt=""
              className="userprofile_matches_connection"
            />
            <img src={profilePhoto} alt="" className="userprofile_matches_user" style={{ borderRadius: "100%" }} />
            <img src={details?.profile_photo} alt="" className="userprofile_matches_user" style={{ borderRadius: "100%" }} />
          </div>
          <div className="userprofile_matches_description">
            <span>You match 8/8 of his</span>
            <p>Desired Preferences</p>
          </div>
          <div className="userprofile_matches_content_wrapper">
            <div className="userprofile_matches_content">
              <p>Age:</p>
              <img src={match} alt="" />
              <p>
                {preference?.min_age || preference?.max_age ? (
                  <>
                    {preference?.min_age} to {preference?.max_age}
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Height</p>
              <img src={match} alt="" />
              <p>
                {preference?.min_height} to {preference?.max_height}
              </p>
            </div>
            <div
              className="userprofile_matches_content"
              style={{ position: "relative" }}
            >
              <p>Marital Status</p>
              <img src={match} alt="" />
              <p>
                <button
                  type="button"
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={preference?.marital_status?.join("\n")}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left",
                  }}
                >
                  {preference?.marital_status?.slice(0, 2).map((e, index) => (
                    <span key={index} className="d-block">
                      {e}
                    </span>
                  ))}
                </button>
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Religion / Community</p>
              <img src={match} alt="" />
              <p>
                <button
                  type="button"
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={religion?.join("\n")}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left",
                  }}
                >
                  {religion && religion?.slice(0, 2)?.map((e) => {
                    return (
                      <>
                        <span className="d-block">{e}</span>
                      </>
                    );
                  })}
                </button>
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Mother Tongue</p>
              <img src={match} alt="" />
              <p>
                <button
                  type="button"
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={language?.join("\n")}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left",
                  }}
                >
                  {language?.slice(0, 2)?.map((e) => {
                    return (
                      <>
                        <span className="d-block">{e} </span>
                      </>
                    );
                  })}
                </button>
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Country Living in</p>
              <img src={match} alt="" />
              <p>{preference?.country}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>State Living in</p>
              <img src={match} alt="" />
              <p>{preference?.location}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>Annual I ncome</p>
              <img src={match} alt="" />
              <p>{preference?.annual_income}</p>
            </div>
            <div className="userprofile_matches_content">
              <p>Diet</p>
              <img src={match} alt="" />
              <p>
                <button
                  type="button"
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={preference?.food_preferences?.join("\n")}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left",
                  }}
                >
                  {preference?.food_preferences?.slice(0, 2)?.map((e) => {
                    return (
                      <>
                        <span className="d-block">{e}</span>
                      </>
                    );
                  })}
                </button>
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Smoke</p>
              <img src={match} alt="" />
              <p>
                <button
                  type="button"
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={preference?.smoke?.join("\n")}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left",
                  }}
                >
                  {preference?.smoke?.slice(0, 2)?.map((e) => {
                    return (
                      <>
                        <span className="d-block">{e}</span>
                      </>
                    );
                  })}
                </button>
              </p>
            </div>
            <div className="userprofile_matches_content">
              <p>Drink</p>
              <img src={match} alt="" />
              <p>
                <button
                  type="button"
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={preference?.drink?.join("\n")}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    textAlign: "left",
                  }}
                >
                  {preference?.drink?.slice(0, 2)?.map((e) => {
                    return (
                      <>
                        <span className="d-block">{e}</span>
                      </>
                    );
                  })}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="userprofile__right">
        <div className="userprofile_intro">
          <div className="userprofile_intro_name">
            <h2 title="User Name">{details?.user_name}</h2>
            <p className="userprofile_intro_height mb-0" title="age , height and weight">
              <HeightIcon style={{ marginRight: "5px" }} />
              {details?.age} yrs, {details?.height}, {details?.weight}kg
            </p>
            <div className="d-sm-flex d-block justify-content-between">
              <div>
                <p className="mb-sm-2 mb-1 " title="community and religion">
                  <PeopleIcon style={{ marginRight: "5px" }} />
                  {details?.community} , {details?.religion}
                </p>
              </div>
              {otherInfo && otherInfo.profile_manage?.length > 0 && (
                <div>
                  <h6>profile managed by:
                    {otherInfo?.profile_manage?.map((e, index) => (
                      <span key={index}>{e}, </span>
                    ))}
                  </h6>
                </div>
              )}
            </div>
            {buttonStatus === "invite" && (
              <>
                <div>
                  <div className="block_buton">
                    <button
                      onClick={() => handleInviteAccept(stateData.id)}
                      className="me-2"
                    >
                      <DoneAllIcon
                        sx={{
                          height: "20px",
                          cursor: "pointer",
                          marginRight: "3px",
                        }}
                      />
                      Accept
                    </button>
                    <button
                      onClick={() => handleInviteDecline(stateData.id)}
                      className="me-2"
                    >
                      <RemoveDoneIcon
                        sx={{
                          height: "20px",
                          cursor: "pointer",
                          marginRight: "3px",
                        }}
                      />
                      Decline
                    </button>
                    <button onClick={openModal}>Block</button>

                    <Modal show={showModal} onHide={closeModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm Block</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to block this user?
                      </Modal.Body>
                      <Modal.Footer>
                        <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                          Cancel
                        </button>
                        <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                          Block
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </>
            )}
            {buttonStatus === "accept" && (
              <>
                <div className="block_buton">
                  <button
                    className="me-2"
                    onClick={() => navigate("/messages")}
                  >
                    <ChatIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Chat
                  </button>
                  <button className="me-2" onClick={handlePaidMember}>
                    <PhoneIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                      onClick={handlePaidMember}
                    />
                    Contact
                  </button>
                  <button
                    onClick={() => handleAcceptDecline(stateData.id)}
                    className="me-2"
                  >
                    <CancelOutlinedIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Decline
                  </button>
                  <button onClick={openModal}>Block</button>

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Block</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to block this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                        Block
                      </button>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Body style={{ paddingTop: "40px" }}>Are you sure you want to see the contact of this user?</Modal.Body>
                    <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
                      <button variant="secondary" onClick={handleClose} style={{
                        border: "none",
                        width: "90px",
                        padding: "7px 10px",
                        color: "white",
                        borderRadius: "10px",
                        background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                      }}>
                        No
                      </button>
                      <button variant="primary" onClick={handleShow1} style={{
                        border: "none",
                        width: "90px",
                        padding: "7px 10px",
                        color: "white",
                        borderRadius: "10px",
                        background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                      }}>
                        Yes
                      </button>
                    </Modal.Footer>
                  </Modal>


                  <Modal show={showSecondModal} onHide={handleClose1}>
                    <Modal.Body style={{ paddingTop: "40px" }} >
                      <div>
                        <div className="d-flex align-items-center justify-content-center">
                          <div >
                            <img src={details?.profile_photo} alt="" style={{ borderRadius: "50%", height: "60px", width: "60px", marginRight: "20px" }} />
                          </div>
                          <div>
                            <div className="">
                              Phone number : {seenContactDetails?.[0]?.contact_no}
                            </div>
                            <div className="">
                              Email : {seenContactDetails?.[0]?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
                      <button variant="primary" onClick={handleClose1} style={{
                        border: "none",
                        width: "90px",
                        padding: "7px 10px",
                        color: "white",
                        borderRadius: "10px",
                        background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                      }}>
                        Close
                      </button>
                    </Modal.Footer>
                  </Modal>



                </div>
              </>
            )}
            {buttonStatus === "pending" && (
              <>
                <div className="block_buton whysoserious">
                  <button onClick={handlePendingConnect} className="me-2">
                    <FavoriteIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Connect
                  </button>
                  <button onClick={openModal}>Block</button>

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Block</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to block this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                        Block
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="whysoser block_buton">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisVertical}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose111}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={openModal}>Block</MenuItem>
                    <MenuItem onClick={handlePendingConnect}>Connect</MenuItem>
                  </Menu>
                </div>
              </>
            )}
            {buttonStatus === "sort" && (
              <>
                <div className="block_buton whysoserious">
                  <button onClick={handleShortlistDelete}>
                    Remove
                  </button>
                  <button onClick={handlePendingConnect} className="me-2">
                    <FavoriteIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Connect
                  </button>
                  <button onClick={openModal}>Block</button>

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Block</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to block this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                        Block
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="whysoser block_buton">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisVertical}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose111}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={openModal}>Block</MenuItem>
                    <MenuItem onClick={handlePendingConnect}>Connect</MenuItem>
                    <MenuItem onClick={handleShortlistDelete}>Remove</MenuItem>
                  </Menu>
                </div>
              </>
            )}
            {buttonStatus === "sent" && (
              <>
                <div className="block_buton whysoserious">
                  <button
                    onClick={() => {
                      navigate("/messages");
                    }}
                    className="me-2"
                  >
                    <ChatIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Chat
                  </button>

                  <button className="me-2" onClick={handlePaidMember}>
                    <PhoneIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                      onClick={handlePaidMember}
                    />
                    Contact
                  </button>



                  <Modal show={show} onHide={handleClose}>
                    <Modal.Body style={{ paddingTop: "40px" }}>Are you sure you want to see the contact of this user?</Modal.Body>
                    <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
                      <button variant="secondary" onClick={handleClose} style={{
                        border: "none",
                        width: "90px",
                        padding: "7px 10px",
                        color: "white",
                        borderRadius: "10px",
                        background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                      }}>
                        No
                      </button>
                      <button variant="primary" onClick={handleShow1} style={{
                        border: "none",
                        width: "90px",
                        padding: "7px 10px",
                        color: "white",
                        borderRadius: "10px",
                        background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                      }}>
                        Yes
                      </button>
                    </Modal.Footer>
                  </Modal>


                  <Modal show={showSecondModal} onHide={handleClose1}>
                    <Modal.Body style={{ paddingTop: "40px" }} >
                      <div>
                        <div className="d-flex align-items-center justify-content-center">
                          <div >
                            <img src={details?.profile_photo} alt="" style={{ borderRadius: "50%", height: "60px", width: "60px", marginRight: "20px" }} />
                          </div>
                          <div>
                            <div className="">
                              Phone number : {seenContactDetails?.[0]?.contact_no}
                            </div>
                            <div className="">
                              Email : {seenContactDetails?.[0]?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
                      <button variant="primary" onClick={handleClose1} style={{
                        border: "none",
                        width: "90px",
                        padding: "7px 10px",
                        color: "white",
                        borderRadius: "10px",
                        background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                      }}>
                        Close
                      </button>
                    </Modal.Footer>
                  </Modal>



                  <button
                    onClick={() => handleSentCancel(stateData.id)}
                    className="me-2"
                  >
                    <CancelOutlinedIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Cancle
                  </button>
                  <button onClick={openModal}>Block</button>

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Block</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to block this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                        Block
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="whysoser block_buton">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisVertical}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose111}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => { navigate("/messages"); }}>Chat</MenuItem>
                    <MenuItem onClick={handlePaidMember}>Contact</MenuItem>
                    <MenuItem onClick={() => handleSentCancel(stateData.id)}>Cancel</MenuItem>
                    <MenuItem onClick={openModal}>Block</MenuItem>
                  </Menu>
                </div>
              </>
            )}
            {buttonStatus === "decline" && (
              <>
                <div className="block_buton">
                  <button
                    onClick={() => handleDeclineDecline(stateData.id)}
                    className="me-2"
                  >
                    <HeartBrokenIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Reconnect
                  </button>
                  <button onClick={openModal}>Block</button>

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Block</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to block this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                        Block
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="whysoser block_buton">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisVertical}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose111}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => handleDeclineDecline(stateData.id)}>Reconnect</MenuItem>
                    <MenuItem onClick={() => handleSentCancel(stateData.id)}>Cancel</MenuItem>
                  </Menu>
                </div>
              </>
            )}
            {buttonStatus === "block" && (
              <>
                <div className="block_buton">
                  <button
                    onClick={openModal1}
                    className="me-2"
                  >
                    Unblock
                  </button>

                  <Modal show={showModal1} onHide={closeModal1}>
                    <Modal.Header closeButton1>
                      <Modal.Title>Confirm Unblock</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to unblock this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal1}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={() => handleUnblock(stateData.id)}>
                        Unblock
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="whysoser block_buton">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisVertical}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose111}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={openModal1}>Unblock</MenuItem>
                  </Menu>
                </div>
              </>
            )}
            {buttonStatus === "cancel" && (
              <>
                <div className="block_buton">
                  <button
                    onClick={() => handleDeclineDecline(stateData.id)}
                    className="me-2"
                  >
                    <CancelOutlinedIcon
                      sx={{
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                    Reconnect
                  </button>

                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm Block</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to block this user?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="block_confirm_button_2" variant="secondary" onClick={closeModal}>
                        Cancel
                      </button>
                      <button className="block_confirm_button" variant="primary" onClick={handleBlock}>
                        Block
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="whysoser block_buton">
                  <div>
                    <FontAwesomeIcon icon={faEllipsisVertical}
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose111}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => handleDeclineCancel(stateData.id)}>Cancel</MenuItem>
                    <MenuItem onClick={() => handleSentCancel(stateData.id)}>Cancel</MenuItem>
                  </Menu>
                </div>
              </>
            )}
          </div>
          <div className="userprofile_intro_bio">
            <h3>Bio</h3>
            <p style={{ overflowWrap: "anywhere" }}>{details?.bio}</p>
          </div>
        </div>
        <div className="userprofile_basic_details">
          <div className="userprofile_section_header">
            <h3>Basic Details</h3>
          </div>
          <div className="userprofile_basic_details_content_wrapper row">
            <div className="userprofile_basic_details_content col-6 mb-2">
              <FemaleOutlined />
              <span title="Gender">{details?.gender}</span>
            </div>
            <div className="userprofile_basic_details_content col-6 mb-2">
              <Language />
              <span title="Mother Tongue">{details?.mother_tongue}</span>
            </div>
            <div className="userprofile_basic_details_content col-6 mb-2">
              <CalendarMonth />
              <span title="Date Of Birth">{details?.dob}
                {otherBirth && otherBirth.birth_time && (
                  <>
                    <span>({otherBirth.birth_time})</span>
                  </>
                )}
              </span>
            </div>
            <div className="userprofile_basic_details_content col-6 mb-2">
              <PersonOutline />
              <span title="Religion">{details?.religion}</span>
            </div>

            {otherBirth && otherBirth.birth_place !== undefined && (
              <div className="col-6 mb-2">
                <div className="userprofile_basic_details_content">
                  <p className="mb-0" title="Religion">
                    <span style={{ fontWeight: "600" }}>Birth Place </span>
                    {otherBirth.birth_place}
                  </p>
                </div>
              </div>
            )}

            <div className="userprofile_basic_details_content col-6 mb-2">
              <img
                src="/weddingring.png"
                style={{
                  height: "20px",
                  fontWeight: "600",
                  paddingRight: "5px",
                }}
              />
              <span title="Marital Status">{details?.marital_status}</span>
            </div>

            <div className="userprofile_basic_details_content col-sm-6 col-12 mb-2">
              <LocationCityOutlined />
              <span title="City , State And Country">Lives in {details?.home_town}, {details?.state},{" "}
                {details?.country}</span>
            </div>


            {currentLocation.current_location_country && (
              <div className="col-6 mb-2">
                <div className="userprofile_basic_details_content">
                  <h6 className="mb-0">current location :</h6>
                  <p className="mb-0">
                    {currentLocation.current_location_city},
                    {currentLocation.current_location_state},
                    {currentLocation.current_location_country}
                  </p>
                </div>
              </div>
            )}
            {/* hobbies  */}


            {hobbies && hobbies.length > 0 && (
              <div className="col-12 mb-2" style={{ overflowWrap: "anywhere" }}>
                <div className="userprofile_basic_details_content">
                  <div className="user-profile-by-id-hobbies-display">
                    <h6 className="mb-0">Hobbies :</h6>
                    <div>
                      {hobbies.map((e, index) => (
                        <p key={index} className="mb-sm-0 mb-1 label_for_p" title="Marital Status">
                          {e}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {aassets && aassets.length > 0 && (

              <div className="col-12 mb-2" style={{ overflowWrap: "anywhere" }}>
                <div className="userprofile_basic_details_content">
                  <h6 className="mb-0 width_sm_hobby">Assets :</h6>
                  <div style={{ display: "flex", flexWrap: "wrap", rowGap: "8px" }}>

                    {aassets && aassets.map((e) => {
                      return (
                        <p className="mb-0 label_for_p" title="Marital Status">{e}</p>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}

            {otherInfo.settling_abroad && (
              <div className="col-6">
                <div className="userprofile_basic_details_content">
                  <h6>Settling In Abroad? : {otherInfo.settling_abroad}</h6>
                </div>
              </div>
            )}


            <div className="userprofile_basic_details_content" style={{ fontWeight: "600" }}>
              Planning to settle-down : {details?.settle_down}
            </div>
          </div>
        </div>

        {otherInfo?.blood_group !== "" || otherInfo?.any_disability !== "" || otherInfo?.hiv !== "" || otherInfo?.thalassemia !== "" ? (<>

          <div className="userprofile_family_details pb-4 ps-3 pt-3">
            <div className="userprofile_section_header">
              <h3>Health Details</h3>
            </div>
            <div className="userprofile_family_details_content_wrapper ps-3">
              {otherInfo.blood_group !== "" && (
                <div className="userprofile_family_details_content">
                  <div>
                    <span>Blood Group</span>
                    <p className="ms-2">{otherInfo && otherInfo.blood_group}</p>
                  </div>
                </div>
              )}
              {otherInfo.any_disability !== "" && (
                <div className="userprofile_family_details_content">
                  <div>
                    <span>Any Disability</span>
                    <p className="ms-2">{otherInfo && otherInfo?.any_disability}</p>
                  </div>
                </div>
              )}
              {otherInfo.hiv !== "" && (
                <div className="userprofile_family_details_content">
                  <div>
                    <span>Hiv</span>
                    <p className="ms-2">{otherInfo && otherInfo?.hiv}</p>
                  </div>
                </div>
              )}
              {otherInfo.thalassemia !== "" && (
                <div className="userprofile_family_details_content">
                  <div>
                    <span>Thalassemia</span>
                    <p className="ms-2">{otherInfo && otherInfo.thalassemia}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>) :

          ""}


        <div className="userprofile_basic_details">
          <div className="userprofile_section_header">
            <h3>Contact</h3>
          </div>
          <div className="userprofile_basic_details_content_wrapper">
            <div className="userprofile_family_details_content">
              <PhoneIcon />
              <div>
                <span>Phone</span>
                <p>{contactDetail?.contact_no}</p>
              </div>
            </div>
            <div className="userprofile_family_details_content">
              <EmailIcon />
              <div>
                <span>Email</span>
                <p>{contactDetail?.Email_id}</p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex align-items-center flex-column">
              <p>To unlock Contact No. & Email ID</p>
              {member_type === "paid" ? (
                <button
                  className="text-white"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  style={{
                    border: "none",
                    padding: "10px 10px",
                    borderRadius: "40px",
                    background:
                      "linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%)",
                  }}
                >
                  View Contact
                </button>
              ) : (
                <button
                  className="text-white"
                  style={{
                    border: "none",
                    padding: "10px 10px",
                    borderRadius: "40px",
                    background:
                      "linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%)",
                  }}
                  onClick={() => navigate("/selectplan")}
                >
                  Upgrade Plan
                </button>
              )}
            </div>
          </div>
        </div>
        {showContactDetails && (
          <div
            className="modal fade bd-example-modal-sm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="mySmallModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                {seenContactDetails.contact_no}
              </div>
              <div className="modal-content">{seenContactDetails.email}</div>
            </div>
          </div>
        )}
        {seenContactDetails ? (
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-content-photo">
                  <div>
                    <div>
                      <img src={details?.profile_photo} alt="" style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                    </div>
                  </div>
                  <div>
                    <div className="">
                      Phone number : {seenContactDetails?.[0]?.contact_no}
                    </div>
                    <div className="">
                      Email : {seenContactDetails?.[0]?.email}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <button
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleModalClose}
                    style={{
                      border: "none",
                      width: "90px",
                      padding: "7px 10px",
                      color: "white",
                      borderRadius: "10px",
                      background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  Are you sure you want to see the contact of this user?
                </div>
                <div
                  className="modal-footer justify-content-center"
                  style={{ borderTop: "none" }}
                >
                  <button
                    type="button"
                    className=""
                    data-dismiss="modal"
                    style={{
                      border: "none",
                      width: "90px",
                      padding: "7px 10px",
                      borderRadius: "10px",
                      color: "white",
                      background:
                        "linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%)",
                    }}
                    onClick={handleModalClose}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className=" md-4"
                    onClick={handleClickYes}
                    style={{
                      border: "none",
                      width: "90px",
                      padding: "7px 10px",
                      borderRadius: "10px",
                      color: "white",
                      background:
                        "linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%)",
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="userprofile_food_preference">
          <div className="userprofile_section_header">
            <h3>Food Preference</h3>
          </div>
          <div className="userprofile_food_preference_content_wrapper">
            <div className="userprofile_food_preference_content">
              <img src={vagan} alt="" />
              <p className="mb-0" title="Food Preference">{details?.food_preference}</p>
            </div>
            <div className="userprofile_food_preference_content">
              <img src={drink} alt="" />
              <p className="mb-0" title="Drink Preference">{details?.drink}</p>
            </div>
            <div className="userprofile_food_preference_content">
              <img src={smoke} alt="" />
              <p className="mb-0" title="Smoke Preference">{details?.smoke}</p>
            </div>
          </div>
        </div>
        <div className="userprofile_education">
          <div className="userprofile_section_header">
            <h3>Education & Career</h3>
          </div>
          <div className="userprofile_education_content_wrapper">
            <div className="userprofile_education_content">
              <WorkOutlineIcon />
              <p>
                <span title="Highest Qualification">{details?.highest_qualification}</span> - <span title="College Name">{details?.college}</span>
              </p>
            </div>
            <div className="userprofile_education_content">
              <MilitaryTechIcon />
              <p title="Company Name">{details?.company_name}</p>
            </div>
            <div className="userprofile_education_content">
              <CalendarTodayIcon />
              <p title="Job Title">{details?.job_title}</p>
            </div>
            {otherInfo?.employed_type?.[0] ? (
              <div className="userprofile_education_content">
                <CalendarTodayIcon />
                <p title="Job Title">{otherInfo && otherInfo?.employed_type?.[0]}</p>
              </div>
            ) : null}
            <div className="userprofile_education_content">
              <BarChart />
              <p title="Income Per Year">Earns INR {details?.salary} annually</p>
            </div>
          </div>
        </div>
        {familyValidation?.length > 0 && (
          <div className="userprofile_family_details ps-3">
            <div className="userprofile_section_header">
              <h3>Family Details</h3>
            </div>
            <div className="userprofile_family_details_content_wrapper ps-3">
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Mother’s Occupation</span>
                  <p>{family?.mother_occupation}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>Father’s Occupatio</span>
                  <p>{family?.father_occupation}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Sister</span>
                  <p>{family?.no_of_sister}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>No Of Married Sister</span>
                  <p>{family?.no_of_married_sister}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>Brother</span>
                  <p>{family?.no_of_brother}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>No Of Married Brother</span>
                  <p>{family?.no_of_married_brother}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {otherInfo.family_income && (
          <div className="userprofile_family_details pb-4 ps-3 pt-3">
            <div className="userprofile_section_header">
              <h3>Family Background</h3>
            </div>
            <div className="userprofile_family_details_content_wrapper ps-3">
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Family Income</span>
                  <p>{otherInfo && otherInfo.family_income}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={father} alt="" />
                <div>
                  <span>Family Status</span>
                  <p>{otherInfo && otherInfo?.family_status}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Family Type</span>
                  <p>{otherInfo && otherInfo?.family_type}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <img src={mother} alt="" />
                <div>
                  <span>Family Values</span>
                  <p>{otherInfo && otherInfo.family_values}</p>
                </div>
              </div>
              <div className="userprofile_family_details_content">
                <div
                  className="userprofile_basic_details_content"
                  style={{ fontWeight: "600" }}
                >
                  Living With Family ? : {otherInfo && otherInfo.living_with_parents}
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default UserDetailById;
