import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Registration4.css";
import country from "../../country.json";
import { faHandFist } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Select from "react-select";
import { State } from "country-state-city";
import ReactSlider from "react-slider";
import Slider from '@mui/material/Slider';
import { BASE_URL } from "../../BASE_URL";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function InsertPartenerPreference() {
  const [preferences, setPreferences] = useState(null);
  console.log(preferences);
  const token = localStorage.getItem("saptvidhiUserToken");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const userDetails = async () => {
    const res = await fetch(
      `${BASE_URL}/api/profile/userdetails`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();

    setPreferences(data?.data?.UserDetails?.preference);

    if (data?.data?.UserDetails?.preference) {
      setJobTitileInput(data?.data?.UserDetails?.preference?.[0]?.job_title);
      setMinHeightInput(data?.data?.UserDetails?.preference?.[0]?.min_height);
      setMaxHeightInput(data?.data?.UserDetails?.preference?.[0]?.max_height);
      setSalaryInput(data?.data?.UserDetails?.preference?.[0]?.annual_income);
      setCountry(data?.data?.UserDetails?.preference?.[0]?.country);
      loadStates(data?.data?.UserDetails?.preference?.[0]?.country);
      setState(data?.data?.UserDetails?.preference?.[0]?.state);
      loadCities(data?.data?.UserDetails?.preference?.[0]?.state);
      setHome_town(data?.data?.UserDetails?.preference?.[0]?.location);
      setOther(data?.data?.UserDetails?.preference?.[0]?.explanation);
      // selectedValues = data?.data?.UserDetails?.preference?.[0]?.marital_status;
      const selectedMaritual =
        data?.data?.UserDetails?.preference?.[0]?.marital_status?.map((status) => ({
          value: status,
          label: status,
        }));
      setSelectedOptionsMaritual(selectedMaritual);
      const selectedoptions2 =
        data?.data?.UserDetails?.preference?.[0]?.religion?.map((status) => ({
          value: status,
          label: status,
        }));
      setSelectedOptions2(selectedoptions2);
      const selectedoptions = data?.data?.UserDetails?.preference?.[0]?.language?.map(
        (status) => ({
          value: status,
          label: status,
        })
      );
      setSelectedOptions(selectedoptions);
      const selectedHigh =
        data?.data?.UserDetails?.preference?.[0]?.highest_qualification?.map(
          (status) => ({
            value: status,
            label: status,
          })
        );
      setSelectedOptionsHigh(selectedHigh);
      const selectedFood =
        data?.data?.UserDetails?.preference?.[0]?.food_preferences?.map((status) => ({
          value: status,
          label: status,
        }));
      setSelectedOptionsFood(selectedFood);
      const selectedDrink =
        data?.data?.UserDetails?.preference?.[0]?.drink?.map((status) => ({
          value: status,
          label: status,
        }));
      setSelectedOptionsDrink(selectedDrink);
      const selectedSmoke = data?.data?.UserDetails?.preference?.[0]?.smoke?.map(
        (status) => ({
          value: status,
          label: status,
        })
      );
      setSelectedOptionsSmoke(selectedSmoke);
    }
  };
  useEffect(() => {
    userDetails();
  }, []);
  useEffect(() => {
    loadCountries();
  }, [countries]);
  useEffect(() => {
    loadCountries2();
  }, [isLoading]);
  const [ageRange, setAgeRange] = useState([18, 80]);

  const navigate = useNavigate();
  const [home_town, setHome_town] = useState("");
  const [country, setCountry] = useState("");



  useEffect(() => {
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      navigate("/");
    }
  }, []);



  const loadCountries = async () => {
    try {
      await axios
        .get("https://www.universal-tutorial.com/api/getaccesstoken", {
          headers: {
            Accept: "application/json",
            "api-token":
              "ftkic-0z5KhVZqsBsoI-tH_7A-1NGcKkOEpGojs1DIKRhL2mHTF1Pdba-oSZMQjDr7E",
            "user-email": "webearlitsolution@gmail.com",
          },
        })
        .then((res) => {
          setAuthToken(res.data.auth_token);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (error) { }
  };
  const loadCountries2 = async () => {
    try {
      await axios
        .get("https://www.universal-tutorial.com/api/countries", {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          setCountries(res.data);
        })
        .catch((err) => console.log(err));
    } catch (error) { }
  };
  const loadStates = async (country) => {
    await axios
      .get(`https://www.universal-tutorial.com/api/states/${country}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
          "user-email": "sarjilp2903@gmail.com",
        },
      })
      .then((res) => {
        setStates(res.data);
      })
      .catch((err) => console.log(err));
  };

  const loadCities = async (state) => {
    await axios
      .get(`https://www.universal-tutorial.com/api/cities/${state}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
          "user-email": "sarjilp2903@gmail.com",
        },
      })
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => console.log(err));
  };
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [religion, setReligion] = useState([]);
  const [motherTongues, setMotherTongues] = useState([]);
  const [highestQualification, setHighestQualification] = useState([]);
  const [salary, setSalary] = useState([]);
  const [foodPreference, setFoodPreference] = useState([]);
  const [drinkPreference, setDrinkPreference] = useState([]);
  const [smokePreference, setSmokePreference] = useState([]);
  const [jobTitlePreference, setJobTitlePreference] = useState([]);
  const [height, setHeight] = useState([]);

  useEffect(() => {
    loadPreference();
  }, []);

  const Token = localStorage.getItem("saptvidhiUserToken");

  const loadPreference = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/preference/preferencedata`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: Token,
          },
        }
      );
      const responseData = await response.json();
      setHeight(responseData.data.height);

      const preferenceData = responseData.data;

      setMaritalStatus(preferenceData.marital_status);
      setReligion(preferenceData.religion);
      setMotherTongues(preferenceData.mother_tongue);
      setHighestQualification(preferenceData.highest_qualification);
      setSalary(preferenceData.salary);
      setFoodPreference(preferenceData.food_preference);
      setDrinkPreference(preferenceData.drink);
      setSmokePreference(preferenceData.smoke);
      setJobTitlePreference(preferenceData.job_title);
      // Check if preference exists
      setPreferences(Object.keys(preferenceData).length > 0);
    } catch (error) {

    }
  };

  const [state, setState] = useState([]);

  const handlecounty = (e) => {
    const getcountryId = e.target.value;
    const getStatedata = country.find(
      (country) => country.country_id === getcountryId
    ).states;
    setState(getStatedata);
  };

  const ageOptions = [];
  for (let age = 18; age <= 100; age++) {
    ageOptions.push(
      <option key={age} value={age}>
        {age}
      </option>
    );
  }

  const [minHeightInput, setMinHeightInput] = useState("");
  const [maxHeightInput, setMaxHeightInput] = useState("");
  const [salaryInput, setSalaryInput] = useState("");
  const [jobTitileInput, setJobTitileInput] = useState("");
  const [other, setOther] = useState("");
  const getHeightCms = (heightString) => {
    if (!heightString) {
      return 0; // Return a default value or handle the undefined case as needed
    }

    const match = heightString.match(/\d+\s*cms/);
    if (match) {
      return parseInt(match[0]);
    }
    return 0; // Or any default value you prefer
  };
  const handleMinHeightChange = (e) => {
    const selectedMinHeight = getHeightCms(e.target.value);
    console.log(selectedMinHeight);
    setMinHeightInput(e.target.value);
    setMaxHeightInput('');
  };

  const handleMaxHeightChange = (e) => {
    const selectedMaxHeight = getHeightCms(e.target.value);
    setMaxHeightInput(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setSalaryInput(e.target.value);
  };
  const handlejobTitleChange = (e) => {
    setJobTitileInput(e.target.value);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      selectedReligion.length < 1 &&
      !minHeightInput &&
      !maxHeightInput &&
      selectedMaritual.length < 1 &&
      !jobTitileInput &&
      selectedHigh.length < 1 &&
      !salaryInput &&
      selectedFood.length < 1 &&
      selectedDrink.length < 1 &&
      selectedValues.length < 1 &&
      selectedSmoke.length < 1 &&
      !country &&
      !state &&
      !home_town &&
      !other

    ) {
      toast.error(`Please Fill All Field`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (selectedReligion.length < 1) {
      toast.error(`Please Select Religion`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!minHeightInput) {
      toast.error(`Please Select Min Height`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!maxHeightInput) {
      toast.error(`Please Select Max Height`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (selectedMaritual.length < 1) {
      toast.error(`Please Select Marital Status`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!jobTitileInput) {
      toast.error(`Please Select Job Title`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (selectedHigh.length < 1) {
      toast.error(`Please Select Highest Qualification`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!salaryInput) {
      toast.error(`Please Select Salary Range`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (selectedFood.length < 1) {
      toast.error(`Please Select Food`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (selectedSmoke.length < 1) {
      toast.error(`Please Select Smoke`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (selectedValues.length < 1) {
      toast.error(`Please Select Value`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (selectedDrink.length < 1) {
      toast.error(`Please Select Drink`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (minHeightInput > maxHeightInput) {
      toast.error(`Enter Correct Value For Maximum Height`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!country) {
      toast.error(`Please Select country Field`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!state) {
      toast.error(`Please Select state Field`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!home_town) {
      toast.error(`Please Select Home Town Field`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!other) {
      toast.error(`Please Enter Other Field`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {

      const formData = {
        marital_status: selectedMaritual,
        religion: selectedReligion,
        language: selectedValues,
        min_height: minHeightInput,
        max_height: maxHeightInput,
        job_title: jobTitileInput,
        min_age: ageRange[0],
        max_age: ageRange[1],
        highest_qualification: selectedHigh,
        annual_income: salaryInput,
        food_preferences: selectedFood,
        drink: selectedDrink,
        smoke: selectedSmoke,
        country: country,
        state: state,
        location: home_town,
        explanation: other,
      };

      for (const key in formData) {
        if (formData[key] === "") {
          toast.error(`Please fill ${key} field`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }
      if (preferences.length > 0 || selectedReligion) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: Token,
          },
          body: JSON.stringify(formData),
        };

        const response = await fetch(
          `${BASE_URL}/api/preference`,
          requestOptions
        );
        const responseData = await response.json();

        if (responseData.code === 200) {
          navigate("/userprofile");
        }
      }
      else {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: Token,
          },
          body: JSON.stringify(formData),
        };

        const response = await fetch(
          `${BASE_URL}/api/preference`,
          requestOptions
        );
        const responseData = await response.json();

        const hyy = localStorage.getItem('editpartenr');

        if (hyy === "partner") {
          navigate('/userprofile');
        } else {
          navigate('/profile');
        }
      }
    } catch (error) {

    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptionsMaritual, setSelectedOptionsMaritual] = useState([]);
  const [selectedOptionsHigh, setSelectedOptionsHigh] = useState([]);
  const [selectedOptionsFood, setSelectedOptionsFood] = useState([]);
  const [selectedOptionsDrink, setSelectedOptionsDrink] = useState([]);
  const [selectedOptionsSmoke, setSelectedOptionsSmoke] = useState([]);

  const selectedValues = selectedOptions?.map((option) => option.value);
  const selectedReligion = selectedOptions2?.map((option) => option.value);
  const selectedMaritual = selectedOptionsMaritual?.map((option) => option.value);
  const selectedHigh = selectedOptionsHigh?.map((option) => option.value);
  const selectedFood = selectedOptionsFood?.map((option) => option.value);
  const selectedDrink = selectedOptionsDrink?.map((option) => option.value);
  const selectedSmoke = selectedOptionsSmoke?.map((option) => option.value);

  const handleSelectChange = (selectedOptions) => {

    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptions([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptions(selectedOptions);
    }
  };

  const handleSelectChange2 = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptions2([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptions2(selectedOptions);
    }
  };
  const options = motherTongues.map((motherTongue) => ({
    value: motherTongue.mt_name,
    label: motherTongue.mt_name,
  }));

  const formatOptionLabel = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const options1 = religion.map((motherTongue) => ({
    value: motherTongue.religious_name,
    label: motherTongue.religious_name,
  }));
  const formatOptionLabel1 = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const optionsMaritual = maritalStatus.map((motherTongue) => ({
    value: motherTongue.status,
    label: motherTongue.status,
  }));
  const formatOptionLabelMaritual = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeMaritual = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptionsMaritual([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsMaritual(selectedOptions);
    }
  };
  const optionsHigh = highestQualification.map((motherTongue) => ({
    value: motherTongue.hq_name,
    label: motherTongue.hq_name,
  }));
  const formatOptionLabelHigh = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeHigh = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptionsHigh([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsHigh(selectedOptions);
    }
  };
  const optionsDrink = drinkPreference.map((motherTongue) => ({
    value: motherTongue.drink_value,
    label: motherTongue.drink_value,
  }));
  const formatOptionLabelDrink = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeDrink = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptionsDrink([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsDrink(selectedOptions);
    }
  };
  const optionsFood = foodPreference.map((motherTongue) => ({
    value: motherTongue.fp_name,
    label: motherTongue.fp_name,
  }));
  const formatOptionLabelFood = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeFood = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptionsFood([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsFood(selectedOptions);
    }
  };
  const optionsSmoke = smokePreference.map((motherTongue) => ({
    value: motherTongue.smoke_value,
    label: motherTongue.smoke_value,
  }));
  const formatOptionLabelSmoke = ({ label, isSelected }) => (
    <div>
      <span>{label}</span>
      {isSelected && <span className="selected-indicator">Selected</span>}
    </div>
  );
  const handleSelectChangeSmoke = (selectedOptions) => {
    const hasDoesntMatter = selectedOptions.some(
      (option) => option.value === "Doesn't Matter"
    );
    if (hasDoesntMatter) {
      setSelectedOptionsSmoke([
        { value: "Doesn't Matter", label: "Doesn't Matter" },
      ]);
    } else {
      setSelectedOptionsSmoke(selectedOptions);
    }
  };

  const [range, setRange] = React.useState([18, 30]);
  function handleChanges(event, newValue) {
    setRange(newValue);
    setAgeRange(newValue)
  }

  const contactnum = localStorage.getItem("contactnumber");
  const password = localStorage.getItem("password");

  const [dell, setDell] = useState({
    contact_no: contactnum,
    password: password,
  });



  const handleLogin = async () => {
    const { contact_no, password } = dell;
    const formattedContactNo = "+91" + contact_no;


    const res = await fetch(`${BASE_URL}/api/profile/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact_no: formattedContactNo,
        password,
      }),
    });

    const abc = await res.json();

    navigate("/profile")

    localStorage.removeItem('preference-detail');


  };


  return (
    <div>
      <div className="p_login__wrapepr">
        <div className="p_login p_login-padding">
          <h2 className="title_partnerpreference pt-sm-2 pt-3">Partner Preference</h2>
          <div className="gender_state">
            <p>Age</p>
            <Slider value={range} onChange={handleChanges} valueLabelDisplay="auto" />
          </div>

          <div className="gender_state">
            <p>height</p>
            <div style={{ display: "flex" }}>
              <select
                className="gender"
                value={minHeightInput}
                onChange={handleMinHeightChange}
                style={{ marginRight: "20px" }}
              >
                <option value="" disabled>min Height</option>
                {height && height?.map((ele, index) => (
                  <option key={index}>{ele.height_value}</option>
                ))}
              </select>
              <select
                className="gender"
                value={maxHeightInput}
                onChange={handleMaxHeightChange}
              >
                <option value="" disabled>
                  max Height
                </option>
                {height
                  .filter((ele) => getHeightCms(ele.height_value) > getHeightCms(minHeightInput))
                  .map((ele, index) => (
                    <option key={index}>{ele.height_value}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="gender_state">
            <p className="mb-1">Marital Status</p>
            <Select
              className="gender"
              options={optionsMaritual}
              isMulti
              value={selectedOptionsMaritual}
              onChange={handleSelectChangeMaritual}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabelMaritual}
              placeholder="Select Marital Status"
            />
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Religion</p>
            <Select
              className="gender"
              options={options1}
              isMulti
              value={selectedOptions2}
              onChange={handleSelectChange2}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabel1}
              placeholder="Select Religion"
            />
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Mother Tounge</p>
            <Select
              className="gender"
              options={options}
              isMulti
              value={selectedOptions}
              onChange={handleSelectChange}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabel}
              placeholder="Select Mother Tounge"
            />
          </div>

          <div className="gender_state">
            <p className="mb-1">Select Highest Qualification</p>
            <Select
              className="gender"
              options={optionsHigh}
              isMulti
              value={selectedOptionsHigh}
              onChange={handleSelectChangeHigh}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabelHigh}
              placeholder="Select Highest Qualification"
            />
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Salary Range</p>
            <select
              className="gender px-2"
              value={salaryInput}
              onChange={handleSalaryChange}
              style={{
                border: "2px solid #f97096",
                height: "40px",
                borderRadius: "5px"
              }}
            >
              <option value="" disabled>Salary</option>
              {salary?.map((ele, index) => (
                <option key={index}>{ele.salary_value}</option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Job Title</p>
            <select
              className="gender px-2"
              value={jobTitileInput}
              onChange={handlejobTitleChange}
              style={{
                border: "2px solid #f97096",
                height: "40px",
                borderRadius: "5px"
              }}
            >
              <option value="" disabled>JobTitle</option>
              {jobTitlePreference?.map((ele, index) => (
                <option key={index}>{ele.jt_name}</option>
              ))}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Country</p>
            <select
              placeholder="Home town"
              // className="settle-down"
              name="country"
              id=""
              value={country}
              className=" px-2"
              style={{
                border: "2px solid #f97096",
                height: "40px",
                borderRadius: "5px"
              }}
              onChange={(e) => {
                setCountry(e.target.value);
                setState("");
                setHome_town("");
                loadStates(e.target.value);
              }}
            >
              <option value="" disabled>Select your country:</option>
              {countries.map((c) => {
                return <option value={c.country_name}>{c.country_name}</option>;
              })}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1">Select State</p>
            <select
              placeholder="Home town"
              name="country"
              value={state}
              className=" px-2"
              style={{
                border: "2px solid #f97096",
                height: "40px",
                borderRadius: "5px"
              }}
              id=""
              onChange={(e) => {
                setState(e.target.value);
                loadCities(e.target.value);
                setHome_town("");
              }}
            >
              <option value="" disabled>Select your State:</option>
              {states.map((c) => {
                return <option value={c.state_name}>{c.state_name}</option>;
              })}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1">Select City</p>
            <select
              placeholder="Home town"
              name="country"
              value={home_town}
              className="px-2"
              style={{
                border: "2px solid #f97096",
                height: "40px",
                borderRadius: "5px"
              }}
              id=""
              onChange={(e) => setHome_town(e.target.value)}
            >
              <option value="" disabled>Select your Home Town:</option>
              {cities.map((c) => {
                return <option value={c.city_name}>{c.city_name}</option>;
              })}
            </select>
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Food Preference</p>
            <Select
              className="gender"
              options={optionsFood}
              isMulti
              value={selectedOptionsFood}
              onChange={handleSelectChangeFood}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabelFood}
              placeholder="Select Food Preference"
            />
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Drink Preference</p>
            <Select
              className="gender"
              options={optionsDrink}
              isMulti
              value={selectedOptionsDrink}
              onChange={handleSelectChangeDrink}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabelDrink}
              placeholder="Select Drink"
            />
          </div>
          <div className="gender_state">
            <p className="mb-1">Select Smoke Preference</p>
            <Select
              className="gender"
              options={optionsSmoke}
              isMulti
              value={selectedOptionsSmoke}
              onChange={handleSelectChangeSmoke}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "2px solid #f97096",
                }),
              }}
              formatOptionLabel={formatOptionLabelSmoke}
              placeholder="Select Smoke"
            />
          </div>
          <div className="gender_state">
            <p className="mb-1 text-start">Other</p>
            <textarea
              name="bio"
              value={other}
              id="bio"

              rows="5"
              style={{ border: "2px solid rgb(249, 112, 150)", width: "100%" }}
              className="bio"
              placeholder="Other"
              onChange={(e) => setOther(e.target.value)}
              required
            ></textarea>
          </div>
          <div id="recaptcha"></div>
          <button className="text-light" onClick={handleSubmit}>
            Submit
          </button>
          <p style={{ textAlign: "center", marginBottom: "15px", cursor: "pointer" }} onClick={handleLogin}>
            skip
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default InsertPartenerPreference;
