import React, { useEffect } from "react";
import "./Profile.css";
import { Message, Search } from "@mui/icons-material";
import RecentVisitors from "../../Sections/Profile/RecentVisitors/RecentVisitors";
import Notifications from "../../Sections/Profile/Notifications/Notifications";
import ProfileDetails from "../../Sections/Profile/ProfileDetails/ProfileDetails";
import Invitations from "../../Sections/Profile/Invitations/Invitations";
import NewMatches from "../../Sections/Profile/NewMatches/NewMatches";
import PremiumMatches from "../../Sections/Profile/PremiumMatches/PremiumMatches";
import Messages from "../../Sections/Profile/MessagesComponent/MessagesComponent";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../BASE_URL";
import OnlineUser from "../Onlineuser/OnlineUser";
import Inclomplete from "../incomplete/Incomplete";
import MessagesComponent from "../../Sections/Profile/MessagesComponent/MessagesComponent";
import SideMessage from "../../Components/SideMessage/Sidemessage";
import { useSocketContext } from "../../context/SocketContext";


const Profile = () => {

  const { onlineUsers } = useSocketContext();

  const [userS, setUSerS] = useState([])
  const [onlineUserList, setOnlineUserList] = useState([])

  useEffect(() => {
    if (Array.isArray(userS) && Array.isArray(onlineUsers)) {
      const filteredArray = userS.filter(user => onlineUsers.includes(user._id));
      setOnlineUserList(filteredArray);
    }
  }, [userS, onlineUsers]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/profile/userlist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();

      setUSerS(responseData?.data);
    } catch (error) {

    }
  };

  const [showIncomplete, setShowIncomplete] = useState(false);
  const [componentsVisible, setComponentsVisible] = useState(true);

  useEffect(() => {
    loadRequests();
    fetchUsers();
  }, []);



  const [recentVisitorsData, setRecentVisitorsData] = useState([]);
  const [data, setData] = useState("");

  useEffect(() => {
    fetchRecentVisitors();

  }, []);

  const fetchRecentVisitors = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/profile/recent_visitor`,
        {
          headers: {
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );
      const recentVisitorsData = response.data.data.recent_visitors;
      const recentVisitorsProfilePhotos =
        response.data.data.recent_visitors[0].profile[0].profile_photo;
      setRecentVisitorsData(recentVisitorsData);
      const Data = response.data.data.recent_visitors.length;
      setData(Data);
    } catch (error) {

    }
  };




  useEffect(() => {
    loadRequests();
    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  const handleWindowClose = () => {
    heyyy();
  }

  const navigate = useNavigate();

  const loadRequests = async () => {

    const token = localStorage.getItem("saptvidhiUserToken");

    if (!token) {
      navigate("/");
      return;
    }


    const res = await fetch(
      `${BASE_URL}/api/profile/userdetails`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
  };



  const heyyy = () => {

    const token = localStorage.getItem("saptvidhiUserToken");
    fetch(`${BASE_URL}/api/online`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }




  useEffect(() => {
    const loginDateTime = localStorage.getItem("loginDateTime");
    const preferenceLocal = localStorage.getItem("preference-detail");
    const fillupLocal = localStorage.getItem("fillup");
    const familyLocal = localStorage.getItem("family-detail");
    const currentDate = new Date().toLocaleDateString();


    const splashscreen = async () => {
      const token = localStorage.getItem("saptvidhiUserToken");

      const res = await fetch(
        `${BASE_URL}/api/profile/splashscreen`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const data = await res.json();

      if (data.message === "preference details is empty" && preferenceLocal === "preference") {
        navigate('/partner-preference');
      }

      if (data.message === "please fill up the data" && fillupLocal === "fill the data") {
        navigate('/personal-deatils');
        localStorage.setItem("returnPersonal", "hyyy");
      }

      if (data.message === "family details is empty" && familyLocal === "family") {
        navigate('/family-detail');
      }

      if (loginDateTime !== currentDate) {

        if (data.message === "please fill health info") {
          navigate('/healthcondition', { state: { message: "health info" } });
        }
        if (data.message === "please fill hiv and thelesseamia") {
          navigate('/healthcondition', { state: { message: "thalassemia" } });
        }
        if (data.message === "please fill settling_abroad and employed type") {
          navigate('/healthcondition', { state: { message: "settledown" } });
        }
        if (data.message === "please fill blood group") {
          navigate('/healthcondition', { state: { message: "blood group" } });
        }
        if (data.message === "please fill any disability") {
          navigate('/healthcondition', { state: { message: "disability" } });
        }
        if (data.message === "please fill profile manage") {
          navigate('/healthcondition', { state: { message: "profile manage" } });
        }
        if (data.message === "please fill hobbies") {
          navigate('/healthcondition', { state: { message: "hobbies" } });
        }
        if (data.message === "document verification is not complete") {
          navigate('/healthcondition', { state: { message: "Document" } });
        }
        if (data.message === "please fill current_location and  assets") {
          navigate('/healthcondition', { state: { message: "country" } });
        }
        if (data.message === "please fill languages_and_speak") {
          navigate('/healthcondition', { state: { message: "language" } });
        }
        if (data.message === "please fill family") {
          navigate('/healthcondition', { state: { message: "family" } });
        }
        if (data.message === "please fill whatsapp") {
          navigate('/healthcondition', { state: { message: "whatsapp" } });
        }
        if (data.message === "please fill birth time and birth place") {
          navigate('/social-media', { state: { message: "birth" } });
        }

      } else {

      }
    };

    splashscreen();
  }, [navigate]);

  const handleAddShow = () => {
    setShowIncomplete(true);
    setComponentsVisible(false);
  };

  return (
    <div className="profile my-container">
      <div className="profile__top">
        <div className="profile__top_left">
          <div className="mobile_version_icons">
            <Link to="/notifications">
              <NotificationsNoneIcon />
            </Link>
            <Link to="/messages">
              <Message />
            </Link>
          </div>
          <ProfileDetails handleAddShow={handleAddShow} />
          {showIncomplete && <Inclomplete />}
          {onlineUserList?.length > 0 && (
            <OnlineUser onlineUserS={onlineUserList} />
          )}
          {componentsVisible && (
            <>
              {recentVisitorsData.length > 0 && (
                <RecentVisitors />
              )}
            </>
          )}
        </div>
        <div className="profile__top_right">
          <Notifications />

          <div className="profile__top_right_content_wrapper">
            {/* <div className="profile_messages_search_input_wrapper">
              <Search />
              <input
                placeholder="Search"
                type="text"
                className="profile_messages_search_input"
              />
            </div> */}
            <SideMessage />
          </div>
        </div>
      </div>
      <div className="profile__bottom">
        {componentsVisible && (
          <>
            <NewMatches />
            <Invitations />
            <PremiumMatches />
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
