import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import countries from "../../CountryStateCity.json";
import "./editpersonaldetails2.css";
import { BASE_URL } from "../../BASE_URL";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const convertDateToInputFormat = (dateStr) => {
    if (!dateStr) return "";
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
};

const calculateAge = (birthDateString) => {
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date has not yet occurred this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};


const convertTo24HourFormat = (time12h) => {
    const [time, period] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    // Convert hours to integer
    hours = parseInt(hours, 10);

    // Convert to 24-hour format
    if (period?.toUpperCase() === 'PM' && hours < 12) {
        hours += 12;
    } else if (period?.toUpperCase() === 'AM' && hours === 12) {
        hours = 0; // Midnight case
    }
}

const EditPersonalDetails2 = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [value, setValue] = useState("1");

    useEffect(() => {
        if (location?.state === "health") {
            setValue("3")
        }

        if (location?.state === "diet") {
            setValue("4")
        }

        if (location?.state === "family") {
            setValue("5")
        }

        if (location?.state === "education") {
            setValue("6")
        }

    }, [location?.state]);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [motherTongues, setMotherTongues] = useState([]);
    const [religionList, setReligionList] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [maritalStatuses, setMaritalStatuses] = useState([]);
    const [settleDownList, setSettleDownList] = useState([]);

    const [formData, setFormData] = useState({
        dob: null,
        age: "",
        height: "",
        weight: null,
        country: "",
        state: "",
        home_town: "",
        religion: "",
        community: "",
        mother_tongue: "",
        marital_status: "",
        bio: "",
        settle_down: "",
    });
    const [selectedHobbies, setSelectedHobbies] = useState([]);
    const [hobbies, setHobbies] = useState([]);
    const [selectedLangauge, setSelectedLangauge] = useState([]);
    const [langauges, setLangauges] = useState([]);
    const [currentStates, setCurrentStates] = useState([]);
    const [currentCities, setCurrentCities] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState([]);

    const [otherInfo, setOtherInfo] = useState({
        hobbies_name: [],
        languages_and_speak: [],
        assets: [],
        current_location_country: "",
        current_location_state: "",
        current_location_city: "",
        birth_time: null
    });
    console.log(otherInfo?.birth_time)

    const convertTo24HourFormat = (time12h) => {
        if (!time12h) return '';

        const [time, period] = time12h.split(' ');
        let [hours, minutes] = time.split(':');

        // Convert hours to integer
        hours = parseInt(hours, 10);

        // Convert to 24-hour format
        if (period?.toUpperCase() === 'PM' && hours < 12) {
            hours += 12;
        } else if (period?.toUpperCase() === 'AM' && hours === 12) {
            hours = 0; // Midnight case
        }

        // Pad single digit hours and minutes with leading zero
        const hoursStr = hours.toString().padStart(2, '0');
        const minutesStr = minutes.padStart(2, '0');

        return `${hoursStr}:${minutesStr}`;
    };

    const handleBirthTimeChange = (e) => {
        const selectedTime = e.target.value;
        setOtherInfo((prevData) => ({
            ...prevData,
            birth_time: selectedTime,
        }));
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const Height = [
        "4’0”", "4’1”", "4’2”", "4’3”", "4’4”", "4’5”", "4’6”", "4’7”",
        "4’8”", "4’9”", "4’10”", "4’11”", "5’0”", "5’1”", "5’2”", "5’3”",
        "5’4”", "5’5”", "5’6”", "5’7”", "5’8”", "5’9”", "5’10”", "5’11”",
        "6’0”", "6’1”", "6’2”", "6’3”", "6’4”", "6’5”", "6’6”", "6’7”",
        "6’8”", "6’9”", "6’10”", "6’11”", "7’0”", "7’1”", "7’2”", "7’3”",
        "7’4”", "7’5”", "7’6”", "7’7”", "7’8”", "7’9”", "7’10”", "7’11”"
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "country") {
            const selectedCountry = countries.find((country) => country.name === value);
            setStates(selectedCountry ? selectedCountry.states : []);
            setCities([]);
            setFormData((prevData) => ({
                ...prevData,
                country: value,
                state: "",
                home_town: "",
            }));
            return;
        }

        if (name === "state") {
            const selectedState = states.find((state) => state.name === value);
            setCities(selectedState ? selectedState.cities : []);
            setFormData((prevData) => ({
                ...prevData,
                state: value,
                home_town: "",
            }));
            return;
        }

        if (name === "religion") {
            fetchCommunities(value)
            setFormData((prevData) => ({
                ...prevData,
                religion: value,
            }));
            return;
        }

        if (name === "dob") {
            const age = calculateAge(value); // Calculate age from DOB
            console.log(age)
            setFormData((prevData) => ({
                ...prevData,
                dob: value,
                age: age.toString(),
            }));
            return;
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCurrentDataChange = (e) => {
        const { name, value } = e.target;

        if (name === "current_location_country") {
            const selectedCountry = countries.find((country) => country.name === value);
            setCurrentStates(selectedCountry ? selectedCountry.states : []);
            setCurrentCities([]);
            setOtherInfo((prevData) => ({
                ...prevData,
                current_location_country: value,
                current_location_state: "",
                current_location_city: "",
            }));
            return;
        }

        if (name === "current_location_state") {
            const selectedState = states.find((state) => state.name === value);
            setCurrentCities(selectedState ? selectedState.cities : []);
            setOtherInfo((prevData) => ({
                ...prevData,
                current_location_state: value,
                current_location_city: "",
            }));
            return;
        }

        setOtherInfo((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    useEffect(() => {

        const fetchReligionPreference = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/religion`
                );
                fetchCommunities(response.data.data[0].religious_name);
                if (Array.isArray(response.data.data)) {
                    setReligionList(response.data.data);
                } else {
                    setReligionList([response.data.data]);
                }
            } catch (error) {
            }
        };

        const fetchMotherTongues = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/mother_tongue`);

                if (Array.isArray(response.data.data)) {
                    setMotherTongues(response.data.data);
                } else {
                    setMotherTongues([response.data.data]);
                }
            } catch (error) {
            }
        };

        const fetchMaritalStatus = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/marital_status`);

                if (Array.isArray(response.data.data)) {
                    setMaritalStatuses(response.data.data);
                } else {
                    setMaritalStatuses([response.data.data]);
                }
            } catch (error) {
            }
        };

        const fetchSettleDown = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/settle_down_value`
                );

                if (Array.isArray(response.data.data)) {
                    setSettleDownList(response.data.data);
                } else {
                    setSettleDownList([response.data.data]);
                }
            } catch (error) {
            }
        };

        const fetchHobbies = async () => {
            const res = await fetch(`${BASE_URL}/api/hobbies`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setHobbies(data.data);
        };

        const fetchLangauge = async () => {
            const res = await fetch(`${BASE_URL}/api/mother_tongue`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setLangauges(data.data);
        };

        fetchReligionPreference()
        fetchMotherTongues()
        fetchMaritalStatus()
        fetchSettleDown()
        fetchHobbies()
        fetchLangauge()

    }, []);

    const fetchCommunities = async (religiousName) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/community?religious_name=${religiousName}`
            );


            if (Array.isArray(response.data.data)) {
                setCommunities(response.data.data);
            } else {
                setCommunities([response.data.data]);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        userDetails()
    }, []);


    const handleNext = async () => {
        const data = {
            dob: formData.dob,
            age: formData.age,
            country: formData.country,
            state: formData.state,
            home_town: formData.home_town,
            height: formData.height,
            weight: formData.weight,
            religion: formData.religion,
            community: formData.community,
            mother_tongue: formData.mother_tongue,
            marital_status: formData.marital_status || "",
            bio: formData.bio || "",
            settle_down: formData.settle_down || "",
        };

        // Create FormData to handle multipart/form-data
        const combinedFormData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            combinedFormData.append(key, value);
        });

        try {
            const token = localStorage.getItem("saptvidhiUserToken");
            const response = await axios.put(
                `${BASE_URL}/api/profile`,
                combinedFormData,
                {
                    headers: {
                        Authorization: token,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                toast.success("Data Updated Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error("Failed to update profile. Please try again later.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };


    const handleSubmit3 = async () => {

        try {
            const token = localStorage.getItem('token');

            // const formattedAddress = `${BirthCity}, ${BirthState}, ${BirthCountry}`;

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({
                    hobbies_name: otherInfo.hobbies_name,
                    languages_and_speak: otherInfo.languages_and_speak,
                    current_location_country: otherInfo.current_location_country,
                    current_location_state: otherInfo.current_location_state,
                    current_location_city: otherInfo.current_location_city,
                    assets: otherInfo.assets,
                    birth_time: otherInfo?.birth_time,
                }),
            });

            if (res1.status === 200) {
                toast.success("Data Updated Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }

        } catch (error) {
            console.log(error)
            toast.error("An unexpected error occurred");
        }
    };

    const options = hobbies.map((hobby) => ({
        value: hobby.hobbies_name,
        label: hobby.hobbies_name,
    }));

    const animatedComponents = makeAnimated();

    const handleHobbyChange = (selectedOptions) => {
        setSelectedHobbies(selectedOptions);
        const newHobbies = selectedOptions.map((option) => option.value); // Store values directly
        setOtherInfo((prevData) => ({
            ...prevData,
            hobbies_name: newHobbies,
        }));
    };

    const options1 = langauges.map((langauge) => ({
        value: langauge.mt_name,
        label: langauge.mt_name,
    }));

    const animatedComponents1 = makeAnimated();

    const handleLangaugeChange = (selectedOptions) => {
        setSelectedLangauge(selectedOptions);
        const newLangauge = selectedOptions.map((option) => option.value); // Store values directly
        setOtherInfo((prevData) => ({
            ...prevData,
            languages_and_speak: newLangauge,
        }));
    };

    const assets = ["Own a House", "Own a Car", "Open To Pets", "Shop/Office", "Other Investment"]

    const options2 = assets.map((langauge) => ({
        value: langauge,
        label: langauge,
    }));

    const animatedComponents2 = makeAnimated();

    const handleAssetsChange = (selectedOptions) => {
        setSelectedLangauge(selectedOptions);
        const newAssets = selectedOptions.map((option) => option.value); // Store values directly
        setOtherInfo((prevData) => ({
            ...prevData,
            assets: newAssets,
        }));
    };


    // health detail start here 

    const [disabilityOption, setDisabilityOption] = useState("");
    const [bloodGroupOption, setBloodGroupOption] = useState("");

    const [selectedThalassemia, setSelectedThalassemia] = useState("");
    const [selectedHiv, setSelectedHiv] = useState("");
    const [selectedDisability, setSelectedDisability] = useState("");
    const [selectedBloodGroup, setSelectedBloodGroup] = useState("");

    useEffect(() => {

        const fetchDisability = async () => {
            const res = await fetch(`${BASE_URL}/api/any_disability`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setDisabilityOption(data.data);
        };

        const fetchBloodGroupList = async () => {
            const res = await fetch(`${BASE_URL}/api/blood_group`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setBloodGroupOption(data.data);
        };


        fetchDisability();
        fetchBloodGroupList();
    }, []);


    const handleHealthDetailSubmit = async () => {
        try {
            const token = localStorage.getItem('token');

            const changedFields = {};
            if (selectedThalassemia !== otherInfo.thalassemia) {
                changedFields.thalassemia = selectedThalassemia;
            }

            if (selectedHiv !== otherInfo.hiv) {
                changedFields.hiv = selectedHiv;
            }

            if (selectedDisability !== otherInfo.any_disability) {
                changedFields.any_disability = selectedDisability;
            }

            if (selectedBloodGroup !== otherInfo.blood_group) {
                changedFields.blood_group = selectedBloodGroup;
            }

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(changedFields),
            });

            if (!res1.ok) {
                // toast.error("Failed to submit family information");
                toast.error('Failed To Submit Health Details.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            toast.success('Health Details Update Successfully.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });


        } catch (error) {
            console.log(error)
            toast.success('An Unexpected Error Found', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };

    // diet information start here 

    const [foodPreference, setFoodPreference] = useState([]);
    const [smokePreference, setSmokePreference] = useState([]);
    const [drinkPreference, setDrinkPreference] = useState([]);

    const [selectedFoodPreference, setSelectedFoodPreference] = useState('');
    const [selectedSmokePreference, setSelectedSmokePreference] = useState('');
    const [selectedDrinkPreference, setSelectedDrinkPreference] = useState('');

    useEffect(() => {

        const fetchFoodPreference = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/food_preference`);
                if (Array.isArray(response.data.data)) {
                    setFoodPreference(response.data.data);
                } else {
                    setFoodPreference([response.data.data]);
                }
            } catch (error) {

            }
        };

        const fetchSmokePreference = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/smoke_status`);
                if (Array.isArray(response.data.data)) {
                    setSmokePreference(response.data.data);
                } else {
                    setSmokePreference([response.data.data]);
                }
            } catch (error) {

            }
        };

        const fetchDrinkPreference = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/drink_status`);
                if (Array.isArray(response.data.data)) {
                    setDrinkPreference(response.data.data);
                } else {
                    setDrinkPreference([response.data.data]);
                }
            } catch (error) {

            }
        };

        fetchFoodPreference();
        fetchSmokePreference();
        fetchDrinkPreference();
    }, []);

    const submitDietPreferences = async () => {
        const token = localStorage.getItem("saptvidhiUserToken");
        const updatedFoodPreference = selectedFoodPreference;
        const updatedSmokePreference = selectedSmokePreference;
        const updatedDrinkPreference = selectedDrinkPreference;

        try {

            const formData = new FormData();
            formData.append('food_preference', updatedFoodPreference);
            formData.append('smoke', updatedSmokePreference);
            formData.append('drink', updatedDrinkPreference);

            const response = await axios.put(
                `${BASE_URL}/api/profile`,
                formData,
                {
                    headers: {
                        Authorization: token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            toast.success('Food Preference Update Successfully!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        } catch (error) {
            toast.error('Network or other error occurred.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };


    // family information start here 

    const [fatherOccupassionList, setFatherOccuPassionList] = useState([])
    const [motherOccupassionList, setMotherOccuPassionList] = useState([])

    const [selectedMotherOccupassion, setSelectedMotherOccupassion] = useState("")
    const [selectedFatherOccupassion, setSelectedFatherOccupassion] = useState("")
    const [selectedNumberOfSister, setSelectedNumberOfSister] = useState("")
    const [selectedNumberOfMarriedSister, setSelectedNumberOfMarriedSister] = useState("")
    const [selectedNumberOfBrother, setSelectedNumberOfBrother] = useState("")
    const [selectedNumberOfMarriedBrother, setSelectedNumberOfMarriedBrother] = useState("")


    const family_status = ["Rich/Affluent", "Upper Middle class", "Middle class"]
    const family_type = ["Joint family", "Nuclear family", "Other"]
    const family_value = ["Orthodox", "Conservative", "Moderate", "Liberal"]
    const living_parent = ["No", "Yes", "Not", "Not Applicable"]
    const family_income = ["0-1 LPA", "1-2 LPA", "2-5 LPA", "5-10 LPA", "10-15 LPA", "15-20 LPA", "20-25 LPA", "25-30 LPA", "30-35 LPA", "35-40 LPA", "45-50 LPA", "50+ LPA"]


    const [selectedFamilyStatus, setSelectedFamilyStatus] = useState("")
    const [selectedFamilyType, setSelectedFamilyType] = useState("")
    const [selectedFamilyValue, setSelectedFamilyValue] = useState("")
    const [selectedLivingWithFamily, setSelectedLivingWithFamily] = useState("")
    const [selectedFamilyIncome, setSelectedFamilyIncome] = useState("")

    useEffect(() => {

        const fetchFatherOccupassion = async () => {
            const res = await fetch(`${BASE_URL}/api/father_occupation`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setFatherOccuPassionList(data.data);
        };

        const fetchMotherOccupassion = async () => {
            const res = await fetch(`${BASE_URL}/api/mother_occupation`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setMotherOccuPassionList(data.data);
        };


        fetchMotherOccupassion();
        fetchFatherOccupassion();
    }, []);

    const generateMarriedSisterOptions = () => {
        const maxMarriedSister = parseInt(selectedNumberOfSister, 10);
        const options = [];

        for (let i = 0; i <= maxMarriedSister; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        return options;
    };

    const generateMarriedBrotherOptions = () => {
        const maxMarriedSister = parseInt(selectedNumberOfBrother, 10);
        const options = [];

        for (let i = 0; i <= maxMarriedSister; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        return options;
    };

    const submitFamily = async () => {
        const token = localStorage.getItem("saptvidhiUserToken");

        try {
            const response = await fetch(`${BASE_URL}/api/family`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    mother_occupation: selectedMotherOccupassion,
                    father_occupation: selectedFatherOccupassion,
                    no_of_sister: selectedNumberOfSister,
                    no_of_married_sister: selectedNumberOfMarriedSister,
                    no_of_brother: selectedNumberOfBrother,
                    no_of_married_brother: selectedNumberOfMarriedBrother,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                if (selectedFamilyStatus && selectedFamilyType && selectedFamilyValue && selectedLivingWithFamily && selectedFamilyIncome) {
                    submitOtherFamily();
                }
                toast.success('Data successfully Updated!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            } else {
                toast.error('An error occurred while submitting data.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('Network or other error occurred.', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };

    const submitOtherFamily = async () => {
        try {
            const token = localStorage.getItem('token');

            const changedFields = {};

            changedFields.family_status = selectedFamilyStatus;
            changedFields.family_type = selectedFamilyType;
            changedFields.family_values = selectedFamilyValue;
            changedFields.living_with_parents = selectedLivingWithFamily;
            changedFields.family_income = selectedFamilyIncome;

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(changedFields),
            });

            if (!res1.ok) {
                toast.error('Failed to submit family information.', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

        } catch (error) {
            toast.error("An unexpected error occurred");
        }
    };

    // education start here 

    const [higherQualification, setHigherQualification] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [salaryRange, setSalaryRange] = useState([]);

    const [selectedQualification, setSelectedQualification] = useState("");
    const [collageName, setCollageName] = useState("");
    const [selectedJob, setSelectedJob] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [selectedSalaryRange, setSelectedSalaryRange] = useState("");

    useEffect(() => {

        const fetchHighestQualification = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/highest_qualification`
                );

                if (Array.isArray(response.data.data)) {
                    setHigherQualification(response.data.data);
                } else {
                    setHigherQualification([response.data.data]);
                }
            } catch (error) {
                console.error("Error fetching Higher Qualification status:", error);
            }
        };

        const fetchSalaryRange = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/api/salary`
                );

                if (Array.isArray(response.data.data)) {
                    setSalaryRange(response.data.data);
                } else {
                    setSalaryRange([response.data.data]);
                }
            } catch (error) {
                console.error("Error fetching Salary Range:", error);
            }
        };

        const fetchJobs = async () => {
            const res = await fetch(`${BASE_URL}/api/job_title`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            setJobs(data.data);
        };

        fetchHighestQualification()
        fetchJobs()
        fetchSalaryRange()

    }, []);

    const submitEducation = async () => {
        try {

            const token = localStorage.getItem("saptvidhiUserToken");

            // if (!formData.highest_qualification) {
            //     toast.error(`Please Select Your Highest Qualification!`, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 1000,
            //     });
            //     return;
            // }
            // if (!formData.college) {
            //     toast.error(`Please Enter Your Collage Name!`, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 1000,
            //     });
            //     return;
            // }
            // if (!formData.job_title) {
            //     toast.error(`Please Select Your Job Type!`, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 1000,
            //     });
            //     return;
            // }
            // if (!formData.company_name) {
            //     toast.error(`Please Enter Your Company Name!`, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 1000,
            //     });
            //     return;
            // }
            // if (!formData.salary) {
            //     toast.error(`Please Select Your Salry Range!`, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 1000,
            //     });
            //     return;
            // }

            const combinedFormData = new FormData();

            combinedFormData.append('highest_qualification', selectedQualification);
            combinedFormData.append('college', collageName);
            combinedFormData.append('job_title', selectedJob);
            combinedFormData.append('company_name', companyName);
            combinedFormData.append('salary', selectedSalaryRange);

            const response = await axios.put(
                `${BASE_URL}/api/profile`,
                combinedFormData,
                {
                    headers: {
                        Authorization: token,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status == 200) {
                toast.success("Education & Career updated Successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });

            }
        } catch (error) {
            console.error("Error storing or updating data:", error);
        }
    };

    const userDetails = async () => {
        const token = localStorage.getItem("saptvidhiUserToken");

        const res = await fetch(
            `${BASE_URL}/api/profile/userdetails`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();
        const userDetails = data?.data?.UserDetails;
        const userFamilyDetails = userDetails?.family?.[0];
        const userFamilyOtherDetails = userDetails?.user_other_infos;

        if (userDetails) {
            // Map userDetails to formData and update states and cities
            const countryData = countries.find((country) => country.name === userDetails.country);
            const stateData = countryData?.states.find((state) => state.name === userDetails.state);

            setStates(countryData ? countryData.states : []);
            setCities(stateData ? stateData.cities : []);

            // Map userDetails to formData and update states and cities
            const currentCountryData = countries.find((country) => country.name === userDetails?.user_other_infos?.current_location_country);
            const currentStateData = currentCountryData?.states.find((state) => state.name === userDetails.user_other_infos?.current_location_state);

            setCurrentStates(currentCountryData ? currentCountryData.states : []);
            setCurrentCities(currentStateData ? currentStateData.cities : []);

            setFormData((prevData) => ({
                ...prevData,
                dob: userDetails.dob || "",
                age: userDetails.age || "",
                height: userDetails.height || "",
                weight: userDetails.weight || "",
                country: userDetails.country || "",
                state: userDetails.state || "",
                home_town: userDetails.home_town || "",
                religion: userDetails.religion || "",
                community: userDetails.community || "",
                mother_tongue: userDetails.mother_tongue || "",
                marital_status: userDetails.marital_status || "",
                bio: userDetails.bio || "",
                settle_down: userDetails.settle_down || "",
            }));

            setOtherInfo((prevData) => ({
                ...prevData,
                hobbies_name: userDetails.user_other_infos?.hobbies_name || [],
                languages_and_speak: userDetails.user_other_infos?.languages_and_speak || [],
                current_location_country: userDetails.user_other_infos?.current_location_country || '',
                current_location_state: userDetails.user_other_infos?.current_location_state || '',
                current_location_city: userDetails.user_other_infos?.current_location_city || '',
                birth_time: convertTo24HourFormat(userDetails.user_other_infos?.birth_time || ''),
            }));

            console.log(userDetails.user_other_infos?.birth_time)


            if (userDetails.user_other_infos?.hobbies_name) {
                const defaultHobbies = userDetails.user_other_infos.hobbies_name.map((hobby) => ({
                    value: hobby,
                    label: hobby,
                }));
                setSelectedHobbies(defaultHobbies);
            }

            if (userDetails?.user_other_infos?.languages_and_speak) {
                const defaultLangauge = userDetails?.user_other_infos?.languages_and_speak?.map((langauge) => ({
                    value: langauge,
                    label: langauge,
                }));
                setSelectedLangauge(defaultLangauge);
            }

            if (userDetails?.user_other_infos?.assets) {
                const defaultAssets = userDetails?.user_other_infos?.assets?.map((asset) => ({
                    value: asset,
                    label: asset,
                }));
                setSelectedAssets(defaultAssets);
            }

            if (userDetails?.user_other_infos) {
                setSelectedThalassemia(userDetails?.user_other_infos?.thalassemia)
                setSelectedHiv(userDetails?.user_other_infos?.hiv)
                setSelectedDisability(userDetails?.user_other_infos?.any_disability)
                setSelectedBloodGroup(userDetails?.user_other_infos?.blood_group)
            }

            if (userDetails) {
                setSelectedFoodPreference(userDetails?.food_preference || "")
                setSelectedSmokePreference(userDetails?.smoke || "")
                setSelectedDrinkPreference(userDetails?.drink || "")
            }

            if (userFamilyDetails) {
                setSelectedMotherOccupassion(userFamilyDetails?.mother_occupation)
                setSelectedFatherOccupassion(userFamilyDetails?.father_occupation)
                setSelectedNumberOfSister(userFamilyDetails?.no_of_sister)
                setSelectedNumberOfMarriedSister(userFamilyDetails?.no_of_married_sister)
                setSelectedNumberOfBrother(userFamilyDetails?.no_of_brother)
                setSelectedNumberOfMarriedBrother(userFamilyDetails?.no_of_married_brother)
            }

            if (userDetails) {
                setSelectedQualification(userDetails?.highest_qualification)
                setCollageName(userDetails?.college)
                setSelectedJob(userDetails?.job_title)
                setCompanyName(userDetails?.company_name)
                setSelectedSalaryRange(userDetails?.salary)
            }

            if (userFamilyOtherDetails?.family_income && userFamilyOtherDetails?.family_status && userFamilyOtherDetails?.family_type && userFamilyOtherDetails?.family_values && userFamilyOtherDetails?.living_with_parents) {
                setSelectedFamilyStatus(userFamilyOtherDetails?.family_status)
                setSelectedFamilyType(userFamilyOtherDetails?.family_type)
                setSelectedFamilyValue(userFamilyOtherDetails?.family_values)
                setSelectedLivingWithFamily(userFamilyOtherDetails?.living_with_parents)
                setSelectedFamilyIncome(userFamilyOtherDetails?.family_income)
            }
        }

    };

    return (
        <div className="container">
            <div style={{ position: "relative" }}>
                <Box className="tab-container-requests">
                    <TabContext value={value}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                display: "flex",
                                gap: "20px",
                                position: "sticky",
                                top: "0px",
                                zIndex: "111",
                                backgroundColor: "white",
                                padding: "20px 0px",
                            }}
                        >
                            <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                                TabIndicatorProps={{ sx: { backgroundColor: "rgba(207, 22, 111, 0.5)" } }}
                                sx={{
                                    "& button": { fontFamily: "poppins" },
                                    "& button:active": { color: "rgba(207, 22, 111, 0.5)" },
                                    "& button.Mui-selected": { color: "rgba(207, 22, 111, 0.5)" },
                                    display: "flex",
                                    gap: "30px",
                                }}
                            >
                                <Tab label="Personal Details" value="1" />
                                {(selectedHobbies.length > 0 || selectedLangauge.length > 0 || selectedAssets.length > 0 || otherInfo.current_location_country || otherInfo.current_location_state || otherInfo.current_location_city) && (
                                    <Tab label="Other Details" value="2" />
                                )}
                                {(selectedThalassemia || selectedHiv || selectedDisability || selectedBloodGroup) && (
                                    <Tab label="Health Details" value="3" />
                                )}
                                <Tab label="Diet Information" value="4" />
                                {(selectedFatherOccupassion || selectedMotherOccupassion || selectedNumberOfBrother || selectedNumberOfMarriedBrother || selectedNumberOfSister || selectedNumberOfMarriedSister) && (
                                    <Tab label="Family Information" value="5" />
                                )}
                                <Tab label="Education Information" value="6" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" className="tabs-padding">
                            <section>
                                <div className="row gy-4">

                                    {/* dob */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="dob">DOB</label>
                                            <input
                                                name="dob"
                                                type="date"
                                                value={formData.dob || ""}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    {/* height */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Height</label>
                                            <select
                                                name="height"
                                                value={formData.height || ""}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {Height.map((e) => (
                                                    <option key={e} value={e}>{e}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* weight */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="weight">Weight</label>
                                            <input
                                                name="weight"
                                                type="text"
                                                value={formData.weight || ""}
                                                onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}
                                                onChange={handleInputChange}
                                                maxLength={3}
                                            />
                                        </div>
                                    </div>

                                    {/* country */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="country">Country</label>
                                            <select
                                                name="country"
                                                value={formData.country || ""}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {countries.map((e) => (
                                                    <option key={e.name} value={e.name}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* state */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="state">State</label>
                                            <select
                                                name="state"
                                                value={formData.state || ""}
                                                onChange={handleInputChange}
                                                disabled={!states.length} // Disable if no states available
                                            >
                                                <option value="">Select</option>
                                                {states.map((e) => (
                                                    <option key={e.name} value={e.name}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* city */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="home_town">City</label>
                                            <select
                                                name="home_town"
                                                value={formData.home_town || ""}
                                                onChange={handleInputChange}
                                                disabled={!cities.length} // Disable if no cities available
                                            >
                                                <option value="">Select</option>
                                                {cities.map((e) => (
                                                    <option key={e.name} value={e.name}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Religion */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="religion">Religion</label>
                                            <select
                                                name="religion"
                                                value={formData.religion || ""}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {/* Populate with actual religion options */}
                                                {religionList && religionList.map((e) => (
                                                    <option key={e.religious_name} value={e.religious_name}>{e.religious_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Community */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="community">Community</label>
                                            <select
                                                name="community"
                                                value={formData.community || ""}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {communities && communities.map((e) => (
                                                    <option value={e.community_name}>{e.community_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Mother Tongue */}
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="mother_tongue">Mother Tongue</label>
                                            <select
                                                name="mother_tongue"
                                                value={formData.mother_tongue || ""}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                {motherTongues && motherTongues.map((e) => (
                                                    <option value={e.mt_name}>{e.mt_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="row">
                                                    {/* marital status  */}
                                                    <div className="col-6">
                                                        <div className="common-edit-form-col">
                                                            <label htmlFor="mother_tongue">Marital Status</label>
                                                            <select
                                                                name="marital_status"
                                                                value={formData.marital_status || ""}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="">Select</option>
                                                                {maritalStatuses && maritalStatuses.map((e) => (
                                                                    <option value={e.status}>{e.status}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {/* settle down  */}
                                                    <div className="col-6">
                                                        <div className="common-edit-form-col">
                                                            <label htmlFor="mother_tongue">Settle Down In</label>
                                                            <select
                                                                name="settle_down"
                                                                value={formData.settle_down || ""}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option value="">Select</option>
                                                                {settleDownList && settleDownList.map((e) => (
                                                                    <option value={e.settle_down_value}>{e.settle_down_value}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* bio  */}

                                            <div className="col-4">
                                                <div className="common-edit-form-col">
                                                    <label htmlFor="mother_tongue">Bio</label>
                                                    <textarea name="bio" value={formData?.bio || ""} onChange={handleInputChange} id="" rows={4}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <button className="new-edit-submit-btn" onClick={handleNext}>Submit</button>
                        </TabPanel>
                        <TabPanel value="2" className="tabs-padding">
                            <section>
                                <div className="row gy-4">

                                    {/* hobiies */}
                                    {selectedHobbies.length > 0 && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="dob">Hobbies</label>
                                                <Select
                                                    name="hobbies_name"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    value={selectedHobbies} // Set current selected value
                                                    onChange={handleHobbyChange} // Update state on change
                                                    isMulti
                                                    options={options} // Dropdown options
                                                    className=""
                                                />
                                            </div>
                                        </div>

                                    )}


                                    {/* langauge speakk */}
                                    {selectedLangauge.length > 0 && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="dob">Langauge Speak</label>
                                                <Select
                                                    name="hobbies_name"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents1}
                                                    value={selectedLangauge} // Set current selected value
                                                    onChange={handleLangaugeChange} // Update state on change
                                                    isMulti
                                                    options={options1}
                                                    className=""
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* assets */}
                                    {selectedAssets.length > 0 && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="dob">assets</label>
                                                <Select
                                                    name="assets"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents2}
                                                    value={selectedAssets} // Set current selected value
                                                    onChange={handleAssetsChange} // Update state on change
                                                    isMulti
                                                    options={options2}
                                                    className=""
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* birth time  */}
                                    <div className="col-6">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Birth Time</label>
                                            <input type="time" onChange={handleBirthTimeChange} name="birth_time" value={otherInfo?.birth_time} id="birth_time" />
                                        </div>
                                    </div>

                                    {/* current country */}
                                    {otherInfo.current_location_country && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="country">Current Country</label>
                                                <select
                                                    name="current_location_country"
                                                    value={otherInfo.current_location_country || ""}
                                                    onChange={handleCurrentDataChange}
                                                >
                                                    <option value="">Select</option>
                                                    {countries.map((e) => (
                                                        <option key={e.name} value={e.name}>{e.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {/* current state */}
                                    {otherInfo.current_location_state && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="state">Current State</label>
                                                <select
                                                    name="current_location_state"
                                                    value={otherInfo.current_location_state || ""}
                                                    onChange={handleCurrentDataChange}
                                                    disabled={!states.length} // Disable if no states available
                                                >
                                                    <option value="">Select</option>
                                                    {currentStates.map((e) => (
                                                        <option key={e.name} value={e.name}>{e.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {/* current city */}
                                    {otherInfo.current_location_city && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="home_town">Current City</label>
                                                <select
                                                    name="current_location_city"
                                                    value={otherInfo.current_location_city || ""}
                                                    onChange={handleCurrentDataChange}
                                                    disabled={!cities.length} // Disable if no cities available
                                                >
                                                    <option value="">Select</option>
                                                    {currentCities.map((e) => (
                                                        <option key={e.name} value={e.name}>{e.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </section>
                            <button className="new-edit-submit-btn" onClick={handleSubmit3}>Submit</button>
                        </TabPanel>
                        <TabPanel value="3" className="tabs-padding">
                            <section>
                                <div className="row gy-4">

                                    {/* thalasemmia  */}

                                    {selectedThalassemia && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="dob">Thalassemia</label>
                                                <div className="edit-form-radion-group">
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="thalassemia"
                                                            id="no"
                                                            value="No"
                                                            checked={selectedThalassemia === "No"}
                                                            onChange={() => setSelectedThalassemia("No")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="no">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="thalassemia"
                                                            id="major"
                                                            value="Major"
                                                            checked={selectedThalassemia === "Major"}
                                                            onChange={() => setSelectedThalassemia("Major")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="major">
                                                            Major
                                                        </label>
                                                    </div>
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="thalassemia"
                                                            id="minor"
                                                            value="Minor"
                                                            checked={selectedThalassemia === "Minor"}
                                                            onChange={() => setSelectedThalassemia("Minor")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="minor">
                                                            Minor
                                                        </label>
                                                    </div>
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="thalassemia"
                                                            id="dont-know"
                                                            value="Don't Know"
                                                            checked={selectedThalassemia === "Don't Know"}
                                                            onChange={() => setSelectedThalassemia("Don't Know")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="dont-know">
                                                            Don't Know
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* HIV +  */}

                                    {selectedHiv && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="dob">HIV +</label>
                                                <div className="edit-form-radion-group">
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="hiv"
                                                            id="no"
                                                            value="No"
                                                            checked={selectedHiv === "No"}
                                                            onChange={() => setSelectedHiv("No")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="no">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="hiv"
                                                            id="yes"
                                                            value="yes"
                                                            checked={selectedHiv === "yes"}
                                                            onChange={() => setSelectedHiv("yes")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="Yes">
                                                            yes
                                                        </label>
                                                    </div>
                                                    <div className="common-edit-form-radion">
                                                        <input
                                                            type="radio"
                                                            name="hiv"
                                                            id="dont-know"
                                                            value="Don't Know"
                                                            checked={selectedHiv === "Don't Know"}
                                                            onChange={() => setSelectedHiv("Don't Know")}
                                                        />
                                                        <label style={{ fontSize: "12px" }} className="mb-0 ms-2" htmlFor="dont-know">
                                                            Don't Know
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Any Disability */}

                                    {selectedDisability && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="height">Any Disability</label>
                                                <select
                                                    name="any_disability"
                                                    value={selectedDisability}
                                                    onChange={(e) => setSelectedDisability(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    {disabilityOption && disabilityOption.map((e) => (
                                                        <option key={e.any_disability} value={e.any_disability}>{e.any_disability}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {/* Selected Blood Group */}

                                    {selectedBloodGroup && (
                                        <div className="col-6">
                                            <div className="common-edit-form-col">
                                                <label htmlFor="height">Select Your Blood Group</label>
                                                <select
                                                    name="blood_group"
                                                    value={selectedBloodGroup}
                                                    onChange={(e) => setSelectedBloodGroup(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    {bloodGroupOption && bloodGroupOption.map((e) => (
                                                        <option key={e.blood_group} value={e.blood_group}>{e.blood_group}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                    {/* submit health information  */}

                                    <div className="col-12">
                                        <button className="new-edit-submit-btn" onClick={handleHealthDetailSubmit}>Submit</button>
                                    </div>

                                </div>
                            </section>
                        </TabPanel>
                        <TabPanel value="4" className="tabs-padding">
                            <section>
                                <div className="row gy-4">

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Food Preference</label>
                                            <select
                                                name="height"
                                                value={selectedFoodPreference}
                                                onChange={(e) => setSelectedFoodPreference(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {foodPreference &&
                                                    foodPreference.map((food, index) => (
                                                        <option key={index} value={food.fp_name}>
                                                            {food.fp_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Smoke Preference</label>
                                            <select
                                                name="height"
                                                value={selectedSmokePreference}
                                                onChange={(e) => setSelectedSmokePreference(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {smokePreference &&
                                                    smokePreference.map((smoke, index) => (
                                                        <option key={index} value={smoke.smoke_value}>
                                                            {smoke.smoke_value}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Drink Preference</label>
                                            <select
                                                name="height"
                                                value={selectedDrinkPreference}
                                                onChange={(e) => setSelectedDrinkPreference(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {drinkPreference &&
                                                    drinkPreference.map((drink, index) => (
                                                        <option key={index} value={drink.drink_value}>
                                                            {drink.drink_value}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>


                                    {/* submit diet information  */}

                                    <div className="col-6">
                                        <button className="new-edit-submit-btn" onClick={submitDietPreferences}>Submit</button>
                                    </div>

                                </div>
                            </section>
                        </TabPanel>
                        <TabPanel value="5" className="tabs-padding">
                            <section>
                                <div className="row gy-4">

                                    {/* father occupassion  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Father Occupassion</label>
                                            <select
                                                name="height"
                                                value={selectedFatherOccupassion}
                                                onChange={(e) => setSelectedFatherOccupassion(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {fatherOccupassionList && fatherOccupassionList.map((option) => (
                                                    <option value={option.name}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* mother occupassion  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Mother Occupassion</label>
                                            <select
                                                name="height"
                                                value={selectedMotherOccupassion}
                                                onChange={(e) => setSelectedMotherOccupassion(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {motherOccupassionList && motherOccupassionList.map((option) => (
                                                    <option value={option.name}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* number of sister  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Number Of Sister</label>
                                            <select
                                                name="height"
                                                value={selectedNumberOfSister}
                                                onChange={(e) => setSelectedNumberOfSister(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* number of married sister  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Number Of Married Sister</label>
                                            <select
                                                name="height"
                                                value={selectedNumberOfMarriedSister}
                                                onChange={(e) => setSelectedNumberOfMarriedSister(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {generateMarriedSisterOptions()}
                                            </select>
                                        </div>
                                    </div>

                                    {/* number of brother  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Number Of Brother</label>
                                            <select
                                                name="height"
                                                value={selectedNumberOfBrother}
                                                onChange={(e) => setSelectedNumberOfBrother(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* number of married brother  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Number Of Married Brother</label>
                                            <select
                                                name="height"
                                                value={selectedNumberOfMarriedBrother}
                                                onChange={(e) => setSelectedNumberOfMarriedBrother(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {generateMarriedBrotherOptions()}
                                            </select>
                                        </div>
                                    </div>

                                    {selectedFamilyStatus && selectedFamilyType && selectedFamilyValue && selectedLivingWithFamily && selectedFamilyIncome && (
                                        <>
                                            {/* Family Status  */}

                                            <div className="col-4">
                                                <div className="common-edit-form-col">
                                                    <label htmlFor="height">Family Status</label>
                                                    <select
                                                        name="height"
                                                        value={selectedFamilyStatus}
                                                        onChange={(e) => setSelectedFamilyStatus(e.target.value)}
                                                    >
                                                        {family_status.map((option) => (
                                                            <option value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Family Type  */}

                                            <div className="col-4">
                                                <div className="common-edit-form-col">
                                                    <label htmlFor="height">Family Type</label>
                                                    <select
                                                        name="height"
                                                        value={selectedFamilyType}
                                                        onChange={(e) => setSelectedFamilyType(e.target.value)}
                                                    >
                                                        {family_type.map((option) => (
                                                            <option value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Family Value  */}

                                            <div className="col-4">
                                                <div className="common-edit-form-col">
                                                    <label htmlFor="height">Family Value</label>
                                                    <select
                                                        name="height"
                                                        value={selectedFamilyValue}
                                                        onChange={(e) => setSelectedFamilyValue(e.target.value)}
                                                    >
                                                        {family_value.map((option) => (
                                                            <option value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Family Value  */}

                                            <div className="col-4">
                                                <div className="common-edit-form-col">
                                                    <label htmlFor="height">Living With Parents?</label>
                                                    <select
                                                        name="height"
                                                        value={selectedLivingWithFamily}
                                                        onChange={(e) => setSelectedLivingWithFamily(e.target.value)}
                                                    >
                                                        {living_parent.map((option) => (
                                                            <option value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Family Income  */}

                                            <div className="col-4">
                                                <div className="common-edit-form-col">
                                                    <label htmlFor="height">Family Income</label>
                                                    <select
                                                        name="height"
                                                        value={selectedFamilyIncome}
                                                        onChange={(e) => setSelectedFamilyIncome(e.target.value)}
                                                    >
                                                        {family_income.map((option) => (
                                                            <option value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    )}


                                    {/* submit family information  */}

                                    <div className="col-12">
                                        <button className="new-edit-submit-btn" onClick={submitFamily}>Submit</button>
                                    </div>

                                </div>
                            </section>
                        </TabPanel>
                        <TabPanel value="6" className="tabs-padding">
                            <section>
                                <div className="row gy-4">

                                    {/* highest qualification  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Highest Qualification</label>
                                            <select
                                                name="height"
                                                value={selectedQualification}
                                                onChange={(e) => setSelectedQualification(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {higherQualification &&
                                                    higherQualification.map((item, index) => (
                                                        <option key={index} value={item.hq_name}>
                                                            {item.hq_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* collage name  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Collage Name</label>
                                            <input
                                                name="collage_name"
                                                type="text"
                                                value={collageName}
                                                onChange={(e) => setCollageName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* job  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Job Title</label>
                                            <select
                                                name="height"
                                                value={selectedJob}
                                                onChange={(e) => setSelectedJob(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {jobs && jobs.map((e) => (
                                                    <option value={e.jt_name}>{e.jt_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* company name  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Company Name</label>
                                            <input
                                                name="company_name"
                                                type="text"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* salary range  */}

                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Salary Range</label>
                                            <select
                                                name="height"
                                                value={selectedSalaryRange}
                                                onChange={(e) => setSelectedSalaryRange(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {salaryRange &&
                                                    salaryRange.map((item, index) => (
                                                        <option key={index} value={item.salary_value}>
                                                            {item.salary_value}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* employee in  */}
                                    {/* 
                                    <div className="col-4">
                                        <div className="common-edit-form-col">
                                            <label htmlFor="height">Number Of Married Brother</label>
                                            <select
                                                name="height"
                                                value={selectedNumberOfMarriedBrother}
                                                onChange={(e) => setSelectedNumberOfMarriedBrother(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                {generateMarriedBrotherOptions()}
                                            </select>
                                        </div>
                                    </div> */}

                                    {/* submit family information  */}

                                    <div className="col-6">
                                        <button className="new-edit-submit-btn" onClick={submitEducation}>Submit</button>
                                    </div>

                                </div>
                            </section>
                        </TabPanel>
                    </TabContext>
                </Box>
                <ToastContainer />
            </div>
        </div>
    );
};

export default EditPersonalDetails2;
