import axios from "axios";
import { useState } from "react";
import { resolvePath, useNavigate } from "react-router-dom";
import "./NewUSER.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from "../../firebase";
import { collection, getDoc, getDocs } from "firebase/firestore";
import { addDoc } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from "../../BASE_URL";

const Registration = () => {
  const [user_name, setuser_name] = useState("");
  const [contact_no, setcontact_no] = useState("");
  localStorage.setItem("contactnumber", contact_no);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  localStorage.setItem("password", password);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);


  const handleSubmit = async () => {
    const data = {
      user_name: user_name,
      contact_no: "+91" + contact_no,
      email: email,
      password: password,
    };
    if (!user_name) {
      toast.error('Please Enter Your Name!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!contact_no) {
      toast.error('Please Enter Mobile Number!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (contact_no.length !== 10) {
      toast.error('Please Enter Valid Contact Number', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!email) {
      toast.error('Please Enter Email Address!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please Enter A Valid Email Address', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!password) {
      toast.error('Please Enter Password!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (!confirmPassword) {
      toast.error('Please Enter Confirm Password As A Password!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast.error('Passwords Do Not Match', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/profile/send-otp`, {
        contact: contact_no,
      });

      if (response.status === 200) {
        localStorage.setItem('usernameForBio', user_name)
        navigate("/Otp", { state: { data: data } });
        localStorage.setItem('otpNumber', contact_no)
        toast.success('OTP sent successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } else {
        toast.error('Failed to send OTP. Please try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.log(error)
      toast.error('Error sending OTP. Please check your network connection and try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }

  };

  // const yuvrajCheck = () => {




  //   // const url = `https://rslri.connectbind.com:8443/bulksms/bulksms?username=di78-trans&password=digimile&type=0&dlr=1&destination=${yuvraj}&source=DIGIML&message=Dear User, Your one time password ${random} and its valid for 15 mins. Do not share to anyone. Digimiles.&entityid=1201159100989151460&tempid=1107162089216820716`;

  // }



  const formatName = (input) => {
    const nameParts = input.split(' ');
    const formattedNameParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
    return formattedNameParts.join(' ');
  };

  const handleInputChange = (e) => {
    const filteredValue = e.target.value.replace(/[0-9]/g, '');
    const formattedName = formatName(filteredValue);
    setuser_name(formattedName);
  };

  return (
    <>
      <div className="login__wrapepr reg_wrapper">
        <div className="login register_page login-padding">
          <h2>User Details</h2>
          <div className="class_for_label">
            <p className="mb-1">Enter Your Name</p>
            <input
              placeholder="Username"
              className=" rounded px-3 py-1"
              type="text"
              value={user_name}
              onChange={handleInputChange}
              required
              style={{ border: "1px solid #f97096", width: "100%" }}
            />
          </div>
          <div className="class_for_label">
            <p className="mb-1">Enter Your Mobile Number</p>
            <input
              placeholder="Mobile Number"
              type="tel"
              name="contact_no"
              className="rounded px-3 py-1"
              id="contact_no"
              maxLength="10"
              onChange={(e) => setcontact_no(e.target.value)}
              onInput={(e) => (e.target.value) = e.target.value.replace(/[^0-9]/g, '')} // This will replace non-numeric characters with an empty string
              required
              style={{
                border: "1px solid #f97096",
                backgroundColor: "transparent",
                width: "100%"
              }}
            />

          </div>
          <div className="class_for_label">
            <p className="mb-1">Enter Your Email</p>
            <input
              placeholder="Email"
              type="email"
              className=" rounded px-3 py-1"
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ border: "1px solid #f97096", width: "100%" }}
            />
          </div>
          <div className="class_for_label">
            <p className="mb-1">Enter Your Password</p>
            {/* <input
              placeholder="Password"
              type="password"
              className=" rounded px-3 py-1"
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ border: "1px solid #f97096", width: "100%" }}
            /> */}
            <div className="eye-password-confirm-relative">
              <input
                type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
                placeholder="Password"
                name="password"
                className=" rounded px-3 py-1"
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ border: "1px solid #f97096", width: "100%" }}
              />
              <div className="eye-password-confirm">
                <FontAwesomeIcon // Eye icon to toggle password visibility
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
          </div>
          <div className="class_for_label">
            <p className="mb-1">Confirm Password</p>
            {/* <input
              placeholder="Confirm Password"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className=" rounded px-3 py-1"
              style={{ border: "1px solid #f97096", width: "100%" }}
            /> */}
            <div className="eye-password-confirm1-relative">
              <input
                type={showPassword1 ? "text" : "password"} // Toggle input type based on showPassword state
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className=" rounded px-3 py-1"
                style={{ border: "1px solid #f97096", width: "100%" }}
              />
              <div className="eye-password-confirm1">
                <FontAwesomeIcon // Eye icon to toggle password visibility
                  icon={showPassword1 ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={() => setShowPassword1(!showPassword1)}
                />
              </div>
            </div>
          </div>
          <div id="recaptcha"><ToastContainer /></div>
          <button onClick={handleSubmit} className="text-light">
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Registration;

