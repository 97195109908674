import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../../BASE_URL";
import "./health.css";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { useLocation } from "react-router-dom";
import axios from "axios";
import OtpInput from 'react-otp-input';
import countries from '../../CountryStateCity.json';

const Health = () => {
    const navigate = useNavigate();

    const contactNo = localStorage.getItem("contact_no");

    const location = useLocation();
    const message = location.state ? location.state.message : null;
    // const message = "hobbies";
    const title = location.state ? location.state.title : null;


    const [health, setHealth] = useState([]);
    const [blood, setBlood] = useState([]);
    const [disable, setDisable] = useState([]);
    const [profile, setProfile] = useState([]);
    const [hobby, setHobby] = useState([]);
    const [employ, setEmploye] = useState([]);
    const [family, setFamily] = useState([]);
    const [selectedHealth, setSelectedHealth] = useState([]);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
    const [selectedDisability, setSelectedDisability] = useState("");
    const [selectedProfile, setSelectedProfile] = useState("");
    const [selectedHobbies, setSelectedHobbies] = useState([]);
    const [selectedThalassemia, setSelectedThalassemia] = useState("");
    const [selectedHiv, setSelectedHiv] = useState("");
    const [selectedFrontImage, setSelectedFrontImage] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [selectedSettle, setSelectedSettle] = useState(null);
    const [selectedEmploy, setSelectedEmploy] = useState(null);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedBackImage, setSelectedBackImage] = useState(null);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [selectedFamilyStatus, setSelectedFamilyStatus] = useState('');
    const [selectedFamilyValue, setSelectedFamilyValue] = useState('');
    const [selectedFamilyType, setSelectedFamilyType] = useState('');
    const [selectedFamilyIncome, setSelectedFamilyIncome] = useState('');
    const [selectedLivingFamily, setSelectedLivingFamily] = useState('');
    const [birthcountries, setBirthCountries] = useState([]);
    const [birthstates, setBirthStates] = useState([]);
    const [birthcities, setBirthCities] = useState([]);
    const [birthtime, setBirthTime] = useState([]);
    const [countryDoc, setCountryDoc] = useState(countries)

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [otp, setOtp] = useState('');

    const Call = async () => {
        const res = await fetch(`${BASE_URL}/api/profile_manage_by`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setProfile(data.data);
    };

    const Call2 = async () => {
        const res = await fetch(`${BASE_URL}/api/health_information`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setHealth(data.data);
    };

    const Call3 = async () => {
        const res = await fetch(`${BASE_URL}/api/blood_group`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setBlood(data.data);
    };

    const Call4 = async () => {
        const res = await fetch(`${BASE_URL}/api/any_disability`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setDisable(data.data);
    };

    const Call5 = async () => {
        const res = await fetch(`${BASE_URL}/api/hobbies`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setHobby(data.data);
    };

    const Call6 = async () => {
        const res = await fetch(`${BASE_URL}/api/employed`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setEmploye(data.data);
    };

    const Call7 = async () => {
        const res = await fetch(`${BASE_URL}/api/mother_tongue`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setFamily(data.data);
    };

    useEffect(() => {
        Call();
        Call2();
        Call3();
        Call4();
        Call5();
        Call6();
        Call7();
    }, []);

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        if (value === "No Health Problem") {
            setSelectedHealth([value]);
        } else {
            setSelectedHealth(selectedHealth.includes("No Health Problem") ? [value] : [...selectedHealth, value]);
        }
    };

    const handleBloodGroupChange = (event) => {
        setSelectedBloodGroup(event.target.value);
    };

    const handleDisabilityChange = (event) => {
        setSelectedDisability(event.target.value);
    };

    const handleProfileChange = (selectedOptions) => {
        const selectedProfile = selectedOptions.map(option => option.value);
        setSelectedProfile(selectedProfile);
    };

    const handleHobbyChange = (selectedOptions) => {
        const selectedHobbies = selectedOptions.map(option => option.value);
        setSelectedHobbies(selectedHobbies);
    };

    const handleThalassemiaChange = (value) => {
        setSelectedThalassemia(value);
    };

    const handleHivChange = (value) => {
        setSelectedHiv(value);
    };

    const handleSettleChange = (value) => {
        setSelectedSettle(value);
    };

    // Helper function to find a country by name
    const findCountry = (countryName) => countries.find((country) => country.name === countryName);

    // Helper function to find a state by name within a country
    const findState = (country, stateName) => country.states.find((state) => state.name === stateName);




    const options1 = hobby.map((hobbies) => ({
        value: hobbies.hobbies_name,
        label: hobbies.hobbies_name,
    }));

    const options2 = profile.map((hobbies) => ({
        value: hobbies.profile_manage,
        label: hobbies.profile_manage,
    }));

    const animatedComponents = makeAnimated();

    const handleFrontImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setSelectedFrontImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleDocChange = (selectedOption) => {
        setSelectedDoc(selectedOption);
    };

    const handleEmployChange = (selectedOption) => {
        setSelectedEmploy(selectedOption.value);
    };

    const docs = [
        { value: "Aadhaar card", label: "Aadhaar card" },
        { value: "Driving Licence", label: "Driving Licence" },
        { value: "Passport", label: "Passport" },
        { value: "Voter Id", label: "Voter Id" },
    ];

    const assets = ["Own a House", "Own a Car", "Open To Pets", "Shop/Office", "Other Investment"]

    const employe = employ.map((emp) => ({
        value: emp.employed_type,
        label: emp.employed_type,
    }));


    const family_status = ["Rich/Affluent", "Upper Middle class", "Middle class"]
    const family_type = ["Joint family", "Nuclear family", "Other"]
    const family_value = ["Orthodox", "Conservative", "Moderate", "Liberal"]
    const living_parent = ["No", "Yes", "Not", "Not Applicable"]
    const family_income = ["0-1 LPA", "1-2 LPA", "2-5 LPA", "5-10 LPA", "10-15 LPA", "15-20 LPA", "20-25 LPA", "25-30 LPA", "30-35 LPA", "35-40 LPA", "40-45 LPA", "45-50 LPA", "50+ LPA"]




    const handleBackImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            // Use FileReader to read the selected file as a data URL
            const reader = new FileReader();

            reader.onloadend = () => {
                setSelectedBackImage(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleAssetsChange = (event) => {
        const selectedAsset = event.target.value;

        // Check if the checkbox is checked or unchecked
        if (event.target.checked) {
            // If checked, add the asset to the selectedAssets array
            setSelectedAssets([...selectedAssets, selectedAsset]);
        } else {
            // If unchecked, remove the asset from the selectedAssets array
            setSelectedAssets(selectedAssets.filter(asset => asset !== selectedAsset));
        }
    };


    const handleLangaugeChange = (event) => {
        const selectedLanguage = event.target.value;

        if (event.target.checked) {
            setSelectedLanguages([...selectedLanguages, selectedLanguage]);
        } else {
            setSelectedLanguages(selectedLanguages.filter(language => language !== selectedLanguage));
        }
    };

    const handleFamilyStatusChange = (event) => {
        setSelectedFamilyStatus(event.target.value);
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
        setSelectedState('');
        setSelectedCity('');
    };

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        setSelectedCity('');
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleLivingFamilyChange = (event) => {
        setSelectedLivingFamily(event.target.value);
    };
    const handleFamilyIncomeChange = (event) => {
        setSelectedFamilyIncome(event.target.value);
    };
    const handleFamilyTypeChange = (event) => {
        setSelectedFamilyType(event.target.value);
    };
    const handleFamilyValueChange = (event) => {
        setSelectedFamilyValue(event.target.value);
    };





    // api calling start here 


    const handleHealthSubmit = async () => {

        if (selectedHealth.length < 1) {
            toast.error('Please Select Health Information!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    health_info: selectedHealth,
                }),
            });

            if (res1.ok) {
                toast.success('Health Information Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleProfileSubmit = async () => {
        if (!selectedProfile) {
            toast.error('Please Select Profile Manage By!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    profile_manage: selectedProfile,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Profile Manage By Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleBloodGroupSubmit = async () => {
        if (!selectedBloodGroup) {
            toast.error('Please Select Blood Group!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    blood_group: selectedBloodGroup,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Blood Group Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error("An unexpected error occurred");
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleDisableSubmit = async () => {
        if (!selectedDisability) {
            toast.error('Please Select Disability!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    any_disability: selectedDisability,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Disability Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleHobbySubmit = async () => {
        if (selectedHobbies.length < 1) {
            toast.error('Please Select Hobbies!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    hobbies_name: selectedHobbies,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Hobbies Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleThelessemiaSubmit = async () => {

        if (!selectedThalassemia && !selectedHiv) {
            toast.error('Please Fill All Field!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!selectedThalassemia) {
            toast.error('Please Select Thalassemia Information!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedHiv) {
            toast.error('Please Select HIV Information!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    thalassemia: selectedThalassemia,
                    hiv: selectedHiv,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Thalassemia & HIV Information Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleSettleSubmit = async () => {
        if (!selectedSettle && !selectedEmploy) {
            toast.error('Please Fill All Fields!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedSettle) {
            toast.error('Please Select Settling Abroad !', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedEmploy) {
            toast.error('Please Select Employee-in!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');


            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    settling_abroad: selectedSettle,
                    employed_type: selectedEmploy,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Data Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleDocSubmit = async () => {

        if (!selectedDoc && !selectedFrontImage && !selectedBackImage) {
            toast.error('Please Fill All Field!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!selectedDoc) {
            toast.error('Please Select A Document Type!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!selectedFrontImage) {
            toast.error('Please Upload Front Photo!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }

        if (!selectedBackImage) {
            toast.error('Please Upload Back Photos!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');
            const formData = new FormData();
            formData.append('doc_type', selectedDoc.value);
            formData.append('front_doc_photo', dataURItoBlob(selectedFrontImage));
            formData.append('back_doc_photo', dataURItoBlob(selectedBackImage));

            // Make the API call for document submission
            const res2 = await fetch(`${BASE_URL}/api/doc_verification`, {
                method: "POST",
                headers: {
                    Authorization: saptvidhiUserToken,
                },
                body: formData,
            });

            if (!res2.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res2.ok) {
                toast.success('Documents submitted successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleFamilySubmit = async () => {
        if (!selectedFamilyIncome && !selectedFamilyStatus && !selectedLivingFamily && !selectedFamilyValue && !selectedFamilyType) {
            toast.error('Please Fill All Fields!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedFamilyStatus) {
            toast.error('Please Select Family Status!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedFamilyType) {
            toast.error('Please Select Family Type!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedFamilyValue) {
            toast.error('Please Select Family Value!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedLivingFamily) {
            toast.error('Please Select Living With!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }
        if (!selectedFamilyIncome) {
            toast.error('Please Select Family Income!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            return;
        }



        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');
            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    family_income: selectedFamilyIncome,
                    family_status: selectedFamilyStatus,
                    living_with_parents: selectedLivingFamily,
                    family_values: selectedFamilyValue,
                    family_type: selectedFamilyType,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Family Detail Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleCountry = async () => {
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');
            if (!selectedCountry && !selectedState && !selectedCity && selectedAssets.length < 1) {
                toast.error('Please fill all fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (!selectedCountry) {
                toast.error('Please Select Country!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (!selectedState) {
                toast.error('Please Select State!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (!selectedCity) {
                toast.error('Please Select City!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (selectedAssets.length < 1) {
                toast.error('Please Select Assets!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    current_location_country: selectedCountry,
                    current_location_state: selectedState,
                    current_location_city: selectedCity,
                    assets: selectedAssets,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Data Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleLanguagesubmit = async () => {
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');

            if (!Array.isArray(selectedLanguages) || selectedLanguages.length === 0) {
                toast.error('Please Select At Least One Language!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    languages_and_speak: selectedLanguages,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (res1.ok) {
                toast.success('Language Speaks Submitted Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleWhatsappSubmit = async () => {
        try {
            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');

            // Check if the OTP is valid (e.g., "1234")
            if (!otp) {
                toast.error('Please Enter A OTP!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (otp !== "1234") {
                toast.error('Please Enter A Valid OTP!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    whatsapp: "verified",
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            // Assuming the API response indicates success in a specific way,
            // modify the condition accordingly based on your API response structure.
            if (res1.ok) {
                toast.success('Whatsapp Verified Successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
                navigate("/profile")
            } else {
                toast.error('Failed to submit documents!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };
    const handleBirthCountry = async () => {

        try {

            if (!selectedCountry && !selectedState && !selectedCity && birthtime.length < 1) {
                toast.error('Please Fill All Fields!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (birthtime.length < 1) {
                toast.error('Please Enter Your Birth Time!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (!selectedCountry) {
                toast.error('Please Select Your Birth Country!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (!selectedState) {
                toast.error('Please Select Your Birth State!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }
            if (!selectedCity) {
                toast.error('Please Select Your Birth City!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            const birthPlaceString = `{${selectedCountry}, ${selectedState}, ${selectedCity}}`;

            const saptvidhiUserToken = localStorage.getItem('saptvidhiUserToken');



            const birthTimeString = birthtime.toString();

            const res1 = await fetch(`${BASE_URL}/api/user_other_info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: saptvidhiUserToken,
                },
                body: JSON.stringify({
                    birth_time: birthTimeString,
                    birth_place: birthPlaceString,
                }),
            });

            if (!res1.ok) {
                toast.error('Failed to submit health information!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                return;
            }

            if (res1.ok) {
                toast.success('Birth Details Added successfully!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    navigate("/profile")
                }, 2000);
            } else {
                toast.error('Failed to submit Birth Details!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error('An unexpected error occurred!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        }
    };



    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const convertTo12HourFormat = (time24) => {
        const [hour, minute] = time24.split(':');
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const twelveHour = (hour % 12) || 12;
        return `${twelveHour}:${minute} ${ampm}`;
    };


    return (
        <>
            <div className="health_box">
                <div className="health">
                    <div className="health_background">

                        {/* done  */}

                        {message === "health info" ? (
                            <>
                                <div className="class_for_label mb-4">
                                    <h5 className="mb-2">Health Condition</h5>
                                    <div className="row">
                                        {health.map((item) => (
                                            <div className="col-6 health_checkbox health_checkbox_css" key={item.id}>
                                                <input
                                                    type="checkbox"
                                                    id={item.id}
                                                    name="healthCondition"
                                                    value={item.health_information}
                                                    checked={selectedHealth.includes(item.health_information)}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor={item.id}>{item.health_information}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleHealthSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "thalassemia" ? (
                            <>
                                <div className="text-center mb-sm-5 mb-3">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label row mb-4 align-items-center thalassemia-css">
                                    <h5 className="mb-0 col-4">Thalassemia :</h5>
                                    <div className="col-8">
                                        <div className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center me-sm-4 me-1">
                                                <input
                                                    type="radio"
                                                    name="thalassemia"
                                                    id="no"
                                                    value="No"
                                                    checked={selectedThalassemia === "No"}
                                                    onChange={() => handleThalassemiaChange("No")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="no">
                                                    No
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center me-sm-4 me-1">
                                                <input
                                                    type="radio"
                                                    name="thalassemia"
                                                    id="major"
                                                    value="Major"
                                                    checked={selectedThalassemia === "Major"}
                                                    onChange={() => handleThalassemiaChange("Major")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="major">
                                                    Major
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center me-sm-4 me-1">
                                                <input
                                                    type="radio"
                                                    name="thalassemia"
                                                    id="minor"
                                                    value="Minor"
                                                    checked={selectedThalassemia === "Minor"}
                                                    onChange={() => handleThalassemiaChange("Minor")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="minor">
                                                    Minor
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="radio"
                                                    name="thalassemia"
                                                    id="dont-know"
                                                    value="Don't Know"
                                                    checked={selectedThalassemia === "Don't Know"}
                                                    onChange={() => handleThalassemiaChange("Don't Know")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="dont-know">
                                                    Don't Know
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="class_for_label row mb-4 align-items-center thalassemia-css">
                                    <h5 className="mb-0 col-4">HIV+ :</h5>
                                    <div className="col-8">
                                        <div className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center me-sm-4 me-1">
                                                <input
                                                    type="radio"
                                                    name="hiv"
                                                    id="no"
                                                    value="No"
                                                    checked={selectedHiv === "No"}
                                                    onChange={() => handleHivChange("No")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="no">
                                                    No
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center me-sm-4 me-1">
                                                <input
                                                    type="radio"
                                                    name="hiv"
                                                    id="yes"
                                                    value="yes"
                                                    checked={selectedHiv === "yes"}
                                                    onChange={() => handleHivChange("yes")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="yes">
                                                    yes
                                                </label>
                                            </div>
                                            <div className="d-flex align-items-center me-sm-4 me-1">
                                                <input
                                                    type="radio"
                                                    name="hiv"
                                                    id="dont-know"
                                                    value="Don't Know"
                                                    checked={selectedHiv === "Don't Know"}
                                                    onChange={() => handleHivChange("Don't Know")}
                                                />
                                                <label className="mb-0 ms-2" htmlFor="dont-know">
                                                    Don't Know
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleThelessemiaSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "settledown" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label mb-4 ">
                                    <h5 className="mb-2">Interesting in settling abroad? </h5>
                                    <div className="d-flex align-items-center ms-sm-3 ms-0 settling-abroad-css">
                                        <div className="d-flex align-items-center me-4">
                                            <input
                                                type="radio"
                                                name="settling_abroad"
                                                id="no"
                                                value="No"
                                                checked={selectedSettle === "No"}
                                                onChange={() => handleSettleChange("No")}
                                            />
                                            <label className="mb-0 ms-2" htmlFor="no">
                                                No
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center me-4">
                                            <input
                                                type="radio"
                                                name="settling_abroad"
                                                id="yes"
                                                value="yes"
                                                checked={selectedSettle === "yes"}
                                                onChange={() => handleSettleChange("yes")}
                                            />
                                            <label className="mb-0 ms-2" htmlFor="yes">
                                                yes
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center me-4">
                                            <input
                                                type="radio"
                                                name="settling_abroad"
                                                id="undecided"
                                                value="Undecided"
                                                checked={selectedSettle === "Undecided"}
                                                onChange={() => handleSettleChange("Undecided")}
                                            />
                                            <label className="mb-0 ms-2" htmlFor="undecided">
                                                Undecided
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="class_for_label d-sm-flex d-block mb-4" style={{ alignItems: "baseline" }}>
                                    <h5> Employed In :</h5>
                                    <Select
                                        name="employed_type"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        onChange={handleEmployChange}
                                        options={employe}
                                        placeholder="select"
                                        className="ms-sm-4 ms-0 select-box-health-css"
                                    />
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleSettleSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "blood group" ? (
                            <>
                                <div className="class_for_label d-sm-flex d-block mb-4">
                                    <h5 >Select Your Blood Group :</h5>
                                    <select
                                        id=""
                                        name="blood_group"
                                        value={selectedBloodGroup}
                                        onChange={handleBloodGroupChange}
                                        className="blood-group-padding"

                                    >
                                        <option value="" selected disabled>Select</option>
                                        {blood && blood.map((e) => {
                                            return (
                                                <option value={e.blood_group}>{e.blood_group}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleBloodGroupSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "disability" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label d-sm-flex d-block mb-5">
                                    <h5>Any Disability :</h5>
                                    <select
                                        id=""
                                        name="any_disability"
                                        value={selectedDisability}
                                        onChange={handleDisabilityChange}
                                        className="blood-group-padding"
                                    >
                                        <option value="" selected disabled>Select</option>
                                        {disable && disable.map((e) => {
                                            return (
                                                <option value={e.any_disability}>{e.any_disability}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleDisableSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "profile manage" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label d-sm-flex d-block mb-4 ">
                                    <h5>Profile Managed By :</h5>
                                    <Select
                                        name="any_disability"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[options1[1]]}
                                        onChange={handleProfileChange}
                                        isMulti
                                        options={options2}
                                        className="ms-sm-4 ms-0 select-box-health-css"
                                    />


                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleProfileSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "hobbies" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label d-sm-flex d-block mb-4" style={{ alignItems: "baseline" }}>
                                    <h5> Hobbies :</h5>
                                    <Select
                                        name="hobbies_name"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        defaultValue={[options1[1]]}
                                        onChange={handleHobbyChange}
                                        isMulti
                                        options={options1}
                                        className="ms-sm-4 ms-0 select-box-health-css"
                                    />
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleHobbySubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* doen  */}

                        {message === "Document" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label mb-5" style={{ alignItems: "baseline" }}>
                                    <h5 className="mb-4">Documents :</h5>
                                    <div className="d-flex align-items-baseline mb-3">
                                        <h6>Select Document Type :</h6>
                                        <Select
                                            name="hobbies_name"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            onChange={handleDocChange}
                                            options={docs}
                                            placeholder="select"
                                            className="ms-4 w-50"
                                        />
                                    </div>
                                    <div className="d-flex" style={{ gap: "60px" }}>
                                        <div className="">
                                            <div className="">
                                                <h6 className="text-center">Front Photo </h6>
                                                <div className="photo_border">
                                                    {selectedFrontImage && (
                                                        <div className="d-flex justify-content-center mb-4">
                                                            <img
                                                                src={selectedFrontImage}
                                                                alt="Selected"
                                                            />
                                                        </div>
                                                    )}

                                                    <input
                                                        type="file"
                                                        accept="image/*"  // Specify accepted file types (in this case, images)
                                                        onChange={handleFrontImageChange}
                                                        style={{ display: "block" }}
                                                    />


                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <h6 className="text-center">Back Photo</h6>
                                                <div className="photo_border">
                                                    {selectedBackImage && (
                                                        <div className="d-flex justify-content-center mb-4">
                                                            <img
                                                                src={selectedBackImage}
                                                                alt="Selected"
                                                            />
                                                        </div>
                                                    )}

                                                    <input
                                                        type="file"
                                                        accept="image/*"  // Specify accepted file types (in this case, images)
                                                        onChange={handleBackImageChange}
                                                        style={{ display: "block" }}
                                                    />


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleDocSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "country" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="gender_state current-country-css d-flex mb-3 mt-5" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Select Your Country :</p>
                                    <select
                                        placeholder="Home town"
                                        className=" rounded px-3 py-1"
                                        name="country"
                                        id=""
                                        style={{ border: "1px solid #f97096", width: "70%" }}
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                    >
                                        <option value="" selected disabled>Select your country:</option>
                                        {countries.map((c) => {
                                            return <option value={c.name}>{c.name}</option>;
                                        })}
                                    </select>
                                </div>
                                <div className="gender_state current-country-css d-flex mb-3" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your State :</p>
                                    <select
                                        placeholder="Home town"
                                        name="country"
                                        className=" rounded px-3 py-1"
                                        id=""
                                        style={{ border: "1px solid #f97096", width: "70%", marginLeft: "23px" }}
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        disabled={!selectedCountry}
                                    >
                                        <option value="" selected disabled>Select your State:</option>
                                        {selectedCountry &&
                                            findCountry(selectedCountry).states.map((state) => (
                                                <option key={state.name} value={state.name}>
                                                    {state.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="gender_state current-country-css d-flex mb-5" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your City :</p>
                                    <select
                                        placeholder="Home town"
                                        name="country"
                                        className=" rounded px-3 py-1"
                                        id=""
                                        style={{ border: "1px solid #f97096", width: "70%", marginLeft: "32px" }}
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        disabled={!selectedState}
                                    >
                                        <option value="" selected disabled>Select your Home Town:</option>
                                        {selectedState &&
                                            findState(findCountry(selectedCountry), selectedState)?.cities.map((city) => (
                                                <option key={city} value={city.name}>
                                                    {city.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div>
                                    <h5 className="mb-2">Assets</h5>
                                    <div className="row">
                                        {assets.map((item) => (
                                            <div className="col-6 health_checkbox health_assets_checkbox" key={item}>
                                                <input
                                                    type="checkbox"
                                                    id={item}
                                                    name="assets"
                                                    value={item}
                                                    onChange={handleAssetsChange}
                                                />
                                                <label htmlFor={item}>{item}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div id="recaptcha"><ToastContainer /></div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleCountry}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "language" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label mb-4">
                                    <h5 className="mb-2">Languages</h5>
                                    <div className="row">
                                        {family.map((item) => (
                                            <div className="col-6 health_checkbox" key={item}>
                                                <input
                                                    type="checkbox"
                                                    id={item}
                                                    name="assets"
                                                    value={item.mt_name}
                                                    onChange={handleLangaugeChange}
                                                />
                                                <label htmlFor={item.mt_name}>{item.mt_name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleLanguagesubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>) : (
                            <></>
                        )}

                        {/* done  */}

                        {message === "family" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label family-status-css mb-4">
                                    <div className="row mb-3">
                                        <h5 className="col-4">Family Status : </h5>
                                        <div className="col-8">
                                            {/* {family_status.map((item) => ( */}
                                            <div className=" health_checkbox ">
                                                {/* <input
                                                        type="radio"
                                                        id={item}
                                                        name="assets"
                                                        value={item}
                                                        onChange={handleFamilyStatusChange}
                                                        checked={selectedFamilyStatus === item}
                                                    />
                                                    <label htmlFor={item}>{item}</label> */}
                                                <select name="" id="" className="w-100" onChange={handleFamilyStatusChange}>
                                                    <option value="" disabled selected>Select Family Status</option>
                                                    {family_status && family_status.map((e) => {
                                                        return (
                                                            <>
                                                                <option value={e}>{e}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {/* ))} */}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <h5 className="col-4">Family Type : </h5>
                                        <div className="col-8">
                                            {/* {family_type.map((item) => (
                                                <div className="col-6 health_checkbox" key={item}>
                                                    <input
                                                        type="radio"
                                                        id={item}
                                                        name="why"
                                                        value={item}
                                                        onChange={handleFamilyTypeChange}
                                                        checked={selectedFamilyType === item}
                                                    />
                                                    <label htmlFor={item}>{item}</label>
                                                </div>
                                            ))} */}
                                            <div className="health_checkbox">
                                                <select name="" id="" className="w-100" onChange={handleFamilyTypeChange}>
                                                    <option value="" disabled selected>Select Family Type</option>
                                                    {family_type && family_type.map((e) => {
                                                        return (
                                                            <>
                                                                <option value={e}>{e}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <h5 className="col-4">Family Value : </h5>
                                        <div className="col-8">
                                            {/* {family_value.map((item) => (
                                                <div className="col-6 health_checkbox" key={item}>
                                                    <input
                                                        type="radio"
                                                        id={item}
                                                        name="so"
                                                        value={item}
                                                        onChange={handleFamilyValueChange}
                                                        checked={selectedFamilyValue === item}
                                                    />
                                                    <label htmlFor={item}>{item}</label>
                                                </div>
                                            ))} */}
                                            <div className="health_checkbox">
                                                <select name="" id="" className="w-100" onChange={handleFamilyValueChange}>
                                                    <option value="" disabled selected>Select Family Value</option>
                                                    {family_value && family_value.map((e) => {
                                                        return (
                                                            <>
                                                                <option value={e}>{e}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <h5 className="col-4">Are you Living With Parents : </h5>
                                        <div className="col-8">
                                            {/* {living_parent.map((item) => (
                                                <div className="col-6 health_checkbox" key={item}>
                                                    <input
                                                        type="radio"
                                                        id={item}
                                                        name="ser"
                                                        value={item}
                                                        onChange={handleLivingFamilyChange}
                                                        checked={selectedLivingFamily === item}
                                                    />
                                                    <label htmlFor={item}>{item}</label>
                                                </div>
                                            ))} */}
                                            <div className="health_checkbox">
                                                <select name="" id="" className="w-100" onChange={handleLivingFamilyChange}>
                                                    <option value="" disabled selected>Select Living WIth</option>
                                                    {living_parent && living_parent.map((e) => {
                                                        return (
                                                            <>
                                                                <option value={e}>{e}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="col-4">Family Income : </h5>
                                        <div className="col-8">
                                            {/* {family_income.map((item) => (
                                                <div className="col-6 health_checkbox" key={item}>
                                                    <input
                                                        type="radio"
                                                        id={item}
                                                        name="ahh"
                                                        value={item}
                                                        onChange={handleFamilyIncomeChange}
                                                        checked={selectedFamilyIncome === item}
                                                    />
                                                    <label htmlFor={item}>{item}</label>
                                                </div>
                                            ))} */}
                                            <div className="health_checkbox">
                                                <select name="" id="" className="w-100" onChange={handleFamilyIncomeChange}>
                                                    <option value="" disabled selected>Select Family Income</option>
                                                    {family_income && family_income.map((e) => {
                                                        return (
                                                            <>
                                                                <option value={e}>{e}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleFamilySubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>) : (
                            <></>
                        )}

                            {/* done  */}

                        {message === "whatsapp" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="class_for_label mb-4">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h6>A verification code has been sent to {contactNo}</h6>
                                            <h6>Enter that code in the box below</h6>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center mt-3">
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                renderSeparator={<span className="ms-2" style={{ width: "20px" }}> </span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleWhatsappSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </>) : (
                            <></>
                        )}

                        {message === "birth" ? (
                            <>
                                <div className="text-center mb-5">
                                    <h3>{title}</h3>
                                </div>
                                <div className="gender_state d-flex mb-3 mt-5" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Enter Your Birth Time :</p>
                                    <div>
                                        <input
                                            type="time"
                                            name="birth_time"
                                            onChange={(e) => {
                                                const selectedTime = e.target.value;
                                                const twelveHourFormat = convertTo12HourFormat(selectedTime);
                                                setBirthTime(twelveHourFormat);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="gender_state d-flex mb-3" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Select Your Birth Country :</p>
                                    <select
                                        placeholder="Home town"
                                        className=" rounded px-3 py-1"
                                        name="birth_place"
                                        id=""
                                        value={selectedCountry}
                                        style={{ border: "1px solid #f97096", width: "70%" }}
                                        onChange={handleCountryChange}
                                    >
                                        <option value="" selected disabled>Select your country:</option>
                                        {countries.map((country) => (
                                            <option key={country.name} value={country.name}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="gender_state d-flex mb-3" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Select Your Birth State :</p>
                                    <select
                                        placeholder="Home town"
                                        name="birth_place"
                                        className=" rounded px-3 py-1"
                                        id=""
                                        style={{ border: "1px solid #f97096", width: "70%", marginLeft: "23px" }}
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        disabled={!selectedCountry}
                                    >
                                        <option value="" selected disabled>Select your State:</option>
                                        {selectedCountry &&
                                            findCountry(selectedCountry).states.map((state) => (
                                                <option key={state.name} value={state.name}>
                                                    {state.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="gender_state d-flex mb-5" style={{ width: "100%", gap: "20px" }}>
                                    <p className="mb-1" style={{ fontWeight: "500" }}>Select Your Birth City :</p>
                                    <select
                                        placeholder="Home town"
                                        name="birth_place"
                                        className=" rounded px-3 py-1"
                                        id=""
                                        style={{ border: "1px solid #f97096", width: "70%", marginLeft: "32px" }}
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        disabled={!selectedState}
                                    >
                                        <option value="" selected disabled>Select your Home Town:</option>
                                        {selectedState &&
                                            findState(findCountry(selectedCountry), selectedState)?.cities.map((city) => (
                                                <option key={city} value={city.name}>
                                                    {city.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div id="recaptcha"><ToastContainer /></div>
                                <div className="d-flex">
                                    <button className="text-light health_submit" onClick={handleBirthCountry}>
                                        Submit
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        <div id="recaptcha"><ToastContainer /></div>
                        <div className="d-flex justify-content-center mt-3">
                            <NavLink to="/profile" className="text-center" style={{ color: "grey" }}>skip</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Health;
