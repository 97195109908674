import React from "react";
import "./Messages.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import {
  EmojiEmotions,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../../BASE_URL";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useSocketContext } from "../../context/SocketContext";

const useListenMessages = ({ messages, setMessages }) => {

  const { socket } = useSocketContext();

  useEffect(() => {
    socket?.on("newMessage", (newMessage) => {
      console.log(newMessage)
      setMessages([...messages, newMessage]);
    });

    return () => socket?.off("newMessage");
  }, [socket, setMessages, messages]);
};
const useListenOnlineUsers = () => {
  const { socket, setOnlineUsers, onlineUsers } = useSocketContext();

  useEffect(() => {
    socket?.on("getOnlineUsers", (users) => {
      setOnlineUsers(users);
    });

    return () => socket?.off("getOnlineUsers");
  }, [socket, setOnlineUsers, onlineUsers]);
};

const Messages = () => {

  const memberType = localStorage.getItem('memberType')

  const { onlineUsers } = useSocketContext();

  const location = useLocation();
  const data = location?.state ? location?.state?.data : null;

  useEffect(() => {
    if (data) {
      getMessage(data?._id)
      userDetails(data?._id)
    }
  }, [data]);

  const [userS, setUSerS] = useState([])
  const [userDetail, setUserDetail] = useState('')
  
  const [messages, setMessages] = useState([]);
  useListenMessages({ messages, setMessages });
  useListenOnlineUsers();

  const [onlineUserList, setOnlineUserList] = useState([])

  useEffect(() => {
    if (Array.isArray(userS) && Array.isArray(onlineUsers)) {
      const filteredArray = userS.filter(user => onlineUsers.includes(user._id));
      setOnlineUserList(filteredArray);
    }
  }, [userS, onlineUsers]);


  const userDetails = async (id) => {
    try {
      const token = localStorage.getItem("saptvidhiUserToken");
      const res = await fetch(
        `${BASE_URL}/api/profile/userdetails?user_id=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      setUserDetail(data.data.UserDetails);

    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };


  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/profile/userlist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();

      setUSerS(responseData?.data);
    } catch (error) {

    }
  };

  const [userList, setUserList] = useState([])

  const fetchAccepted = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/request/confirm`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("saptvidhiUserToken"),
        },
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();
      setUserList(responseData.data)
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchAccepted();
  }, []);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const sendMessage = async (e) => {

    const matchedUser = userList.find(user => user.profile && user.profile[0] && user.profile[0]._id === userDetail._id);

    if (!matchedUser && memberType === "free") {
      handleShow2()
      return;
    }

    try {
      const response = await fetch(
        `${BASE_URL}/api/message/send/${userDetail._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("saptvidhiUserToken"),
          },
          body: JSON.stringify({ message: message }),
        },
      );


      if (!response.ok) {
        throw new Error("Request failed");
      }

      setMessage("")
      getMessage(userDetail._id)

      const responseData = await response.json();

      // setUSerS(responseData?.data);
    } catch (error) {

    }
  };

  const getMessage = async (id) => {
    const token = localStorage.getItem("saptvidhiUserToken");
    const res = await fetch(`${BASE_URL}/api/message/messages/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    const data = await res.json();
    setMessages(data)
  }

  useEffect(() => {
    fetchUsers();
  }, []);


  const [Yay, setYay] = useState([]);
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      navigate("/");
    }
  }, []);


  const fetchData = async () => {
    try {
      const res = await fetch(`${BASE_URL}/api/profile/displayuserchat`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const data = await res.json();
      setYay(data?.data || []);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [message, setMessage] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [openMessages, setOpenMessages] = useState(false);
  const [messagesClasses, setMessagesClasses] = useState(
    "profile__messages_wrapper"
  );


  useEffect(() => {
    const token = localStorage.getItem("saptvidhiUserToken");
    if (!token) {
      navigate("/");
    }
  }, []);

  const handleViewAll = () => {
    if (!openMessages) {
      setMessagesClasses(
        "profile__messages_wrapper profile__messages_wrapper_full"
      );
      setOpenMessages(true);
    } else {
      setMessagesClasses("profile__messages_wrapper");
      setOpenMessages(false);
    }
  };

  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOnClick = async (item) => {
    getMessage(item._id)
    setUserDetail(item)
  };

  const handleNavigate = (e) => {
    userDetails(e._id)
    getMessage(e._id)
  }

  const handleProfile = (profileId) => {
    navigate(`/userprofile/${profileId}`)
  }

  const [userDetailsArray, setUserDetailsArray] = useState([]);

  const fetchMessageList = async () => {
    try {
      const res = await fetch(`${BASE_URL}/api/message/allMessageDisplay`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      const data = await res.json();
      setUserDetailsArray(data?.data || []);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchMessageList();
  }, []);

  const handleNavigateUpdagrade = () => {
    navigate("/selectplan")
  }

  return (
    <div className="messages row mx-md-5 mx-sm-3 mx-2">
      <div className="col-xl-8 col-lg-8">
        <div className="messages_left">
          <div className="online_persons mb-3">
            <div className="online_users">
              <h3>Online</h3>
              <div className="ms-1 online_users_chatbox">
                <Swiper
                  slidesPerView={14}
                  spaceBetween={30}
                  className="mySwiper"
                >
                  {onlineUserList && onlineUserList.map((profile, index) => (
                    <SwiperSlide>
                      <div key={index} className="online_box text-center align-items-center me-2" onClick={() => handleOnClick(profile)}>
                        <img
                          src={profile.profile_photo}
                          style={{ height: '65px', width: '65px', borderRadius: '100%' }}
                          className="cursor-pointer-class"
                        />
                        <p className="mb-0 ms-2 cursor-pointer-class">{profile.user_name.slice(0, 7)}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

              </div>
            </div>
          </div>
          <div className="messages_header">
            <div className="messages_header_profile">
              {userDetail ? (
                <>
                  <img className="cursor-pointer-class" onClick={() => handleProfile(userDetail?._id)} src={userDetail?.profile_photo} style={{ borderRadius: "50%" }} alt="" />
                  <h3 className="cursor-pointer-class" onClick={() => handleProfile(userDetail?._id)}>{userDetail.user_name}</h3>
                </>
              ) : (
                <p>No User Details Available</p>
              )}
            </div>
            <MoreVertIcon />
          </div>
          <div className="messages_chat_screen">
            <div className="messages_message_panel">
              {messages && messages.map((message) => {
                // Get the current user ID from localStorage
                const myId = localStorage.getItem('myId');

                // Determine the appropriate class name based on the matching criteria
                const className = message.senderId === myId
                  ? 'yellow-text'
                  : 'red-text'

                    console.log(message.senderId)
                    console.log(myId)
                    console.log(className)

                return (
                  <div key={message.id} className={className}>
                    <p>{message.message}</p>
                  </div>
                );
              })}

              {/* <div ref={messagesEndRef} /> */}
            </div>


            <div className="messages_input_form">
              <EmojiEmotions style={{ color: "#f97096" }} />
              <div className="messages_chat_input_wrapper">
                <input
                  placeholder="Send a message"
                  type="text"
                  className="messages_chat_input"
                  name='message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage();
                    }
                  }}
                />
                <button
                >
                  {" "}
                  <SendIcon
                    onClick={sendMessage}
                  />
                </button>

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Upgrade Plan</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p className="mb-1">You are not primium member</p>
                    <p>For a Chat You need to Upgrade Plan</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="cancel_plan_buton" variant="secondary" onClick={handleClose}>
                      Close
                    </button>
                    <button className="upgrade_plan_buton" variant="primary" onClick={handleNavigate}>Upgrade</button>
                  </Modal.Footer>
                </Modal>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 mb-5">
        <div className="messages_right">
          <div className="profile__messages">
            <Link to="/profile" className="porfile__messages_back_icon">
              <ArrowBackIcon />
            </Link>
            <div className="profile__messages_messages__header">
              <div className="mprofile__messages_messages__header_details">
                <h3>
                  New Message <span>06</span>
                </h3>
              </div>
              <button className="messages__view_all" onClick={() => handleViewAll()}>
                {openMessages ? "Close All" : "View All"}
              </button>
            </div>
            <div className={messagesClasses}>
              {userDetailsArray.map((profile, index) => (
                <div key={index} className="profilemessagecard" style={{ cursor: "pointer" }} onClick={() => handleNavigate(profile.profile)}>
                  <img src={profile.profile.profile_photo} style={{ borderRadius: "50%" }} alt="" />
                  <div className="profilemessage__details">
                    <p className="profilemessage__name mb-0"><b>{profile.profile.user_name}</b></p>
                    <p>{profile.latestMessage.message}</p>
                  </div>
                  <div className="profilemessage__time">
                    <p className="profilemessage_day">Today</p>
                    <p className="profilemessage_unread_messages">1</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body style={{ paddingTop: "40px" }} >
          <div>
            <h5 className="text-center">To Start Chatting With Your Matches</h5>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none", display: "flex", justifyContent: "center", width: "100%" }}>
          <button variant="primary" onClick={() => handleProfile(userDetail?._id)}  style={{
            border: "none",
            width: "140px",
            padding: "7px 10px",
            color: "white",
            borderRadius: "10px",
            background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
          }}>
            Connect Now
          </button>
          <button variant="primary" onClick={handleNavigateUpdagrade} style={{
            border: "none",
            width: "140px",
            padding: "7px 10px",
            color: "white",
            borderRadius: "10px",
            background: "linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%)"
          }}
          >
            Upgrade Now
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Messages;